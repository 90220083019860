import { Link, useParams } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import { TableSorting } from "../../components/Table/Table";
import classes from "./Sensors.module.scss";
import { Cell } from "@table-library/react-table-library/table";
import { createApiService } from "../../utils/api";
import { config } from "../../constants";
import { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { timeToReadable } from "../../utils/display";
import { SensorSource } from "../../utils/types/Sensor";

const fields = [
  {
    name: "Source",
    sortKey: "SOURCE"
  },
  {
    name: "Input Type",
    sortKey: "INPUTTYPE"
  },
  {
    name: "Last updated",
    sortKey: "TIMESTAMP"
  },
  {
    name: "Data Confidence",
    sortKey: "DATACONFIDENCE"
  },
  {
    name: "Data Type",
    sortKey: "DATATYPE"
  },
  {
    name: "Data Entry",
    sortKey: "DATAENTRY"
  },
]

const sortFns = {
  SOURCE: (array: Array<any>) => array.sort((a, b) => a.source.localeCompare(b.source)),
  INPUTTYPE: (array: Array<any>) => array.sort((a, b) => a.inputType.localeCompare(b.inputType)),
  TIMESTAMP: (array: Array<any>) => array.sort((a, b) => a.timestamp.localeCompare(b.timestamp)),
  DATACONFIDENCE: (array: Array<any>) => array.sort((a, b) => a.dataConfidence.localeCompare(b.dataConfidence)),
  DATATYPE: (array: Array<any>) => array.sort((a, b) => a.dataType.localeCompare(b.dataType)),
  DATAENTRY: (array: Array<any>) => array.sort((a, b) => a.dataEntry.localeCompare(b.dataEntry)),
}

const ActivityRowTemplate: React.FC<{ item: any }> = ({ item }) => (
  <>
    <Cell className={classes.source}><Link to={item.url}>{item.source ?? <Skeleton/>}</Link></Cell>
    <Cell className={classes.inputType}>{item.inputType ?? <Skeleton/>}</Cell>
    <Cell className={classes.timestamp}>{item.timestamp ?? <Skeleton/>}</Cell>
    <Cell className={classes.dataConfidence}>
      {item.dataConfidence !== null && item.dataConfidence !== undefined ? (
        <div className={classes.wrapper}>
          <div className={classes.progressBar}>
            <div
              className={classes.progressPercentage}
              style={{ width: Math.max(0, Math.min(item.dataConfidence, 100)) }}
            ></div>
          </div>
          <p>{ Math.max(0, Math.min(item.dataConfidence, 100)) }</p>
        </div>
       ) : <Skeleton /> }
    </Cell>
    <Cell className={classes.dataType}>{item.dataType ?? <Skeleton/>}</Cell>
    <Cell className={classes.dataEntry}>{item.dataEntry ?? <Skeleton/>}</Cell>
  </>
);

export const Sensors = () => {
  const { slug } = useParams();
  const api = createApiService(config.API_BASE_URL);
  
  const [sensors, setSensors] = useState <SensorSource[]>(api.cache.sensors);

  useEffect(() => {
    api.context.getSensors(slug!).then((sensors) => {
      let mappedSensors = sensors.map((s) => {
        return {
          source: s.equipment.name,
          url: s.id,
          inputType: s.equipment.eqType,
          timestamp: timeToReadable(new Date(s.lastUpdated)),
          dataConfidence: Math.round(s.avgcf*100)/100,
          // Sensor / Sheet
          dataType: s.equipment.group == "Manual" ? "Sheet" : "Sensor",
          // Manual / Automatic
          dataEntry: s.equipment.group == "Manual" ? "Manual" : "Automated",
        }
      })
      setSensors(mappedSensors);
    });
  }, []);

  useEffect(() => {
    api.cache.setSensors(sensors);
  }, [sensors])


  return (
    <Layout>
      <div className={classes.sensors}>
        <TableSorting
          title="Data Sources"
          data={{ nodes: sensors }}
          fields={fields}
          RowTemplate={ActivityRowTemplate}
          sortFns={sortFns}
        />
      </div>
    </Layout>
  );
};

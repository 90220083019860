import { ReactSVG } from "react-svg";
import { ProjectStatisticPropsType } from "../../utils/types";
import classes from "./ProjectStatistic.module.scss";
import { chart, sustainability } from "../../assets";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export const ProjectStatistic: React.FC<ProjectStatisticPropsType> = (
  {statistic}
) => {

  const mapIconToType: any = {
      "sustainability": sustainability,
      "chart": chart
  }

  return (
    <div className={classes.statistic}>
      <div className={classes.amount}>
        <ReactSVG src={mapIconToType[statistic.type]} />
        <p>{statistic.amount || <Skeleton width={100}/> } {statistic.unit || <Skeleton/>}</p>
        {statistic.percentage ? <span>{statistic.percentage}</span> : null}
      </div>
      <p className={classes.text}>{statistic.text}</p>
    </div>
  );
};

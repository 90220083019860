import classes from "./IdentityMethod.module.scss";
import { Cell } from "@table-library/react-table-library/table";
import type { IdentityMethod as MethodFields } from "../../utils/types";

export interface IdentityMethodProps {
  item: MethodFields
};

export const IdentityMethod: React.FC<IdentityMethodProps> = ({ item }) => {
  return (
    <>
      <Cell>
          <span className={classes["text"]}>{item.fragment}</span>
      </Cell>
      <Cell>
          <span className={classes["text"]}>{item.type_}</span>
      </Cell>
      <Cell>
          <span className={classes["text"]}>{item.controller}</span>
      </Cell>
    </>
  );
};

import {useNavigate, useParams} from "react-router-dom";
import { ProjectInfo, ProjectStatistic } from ".";
import { RecentActivity } from "../Activity";
import classes from "./Project.module.scss";
import {createApiService} from "../../utils/api";
import {config} from "../../constants";
import {useEffect, useState} from "react";
//import {IProjectActivity} from "../../utils/types/Project";
import {ProjectStatisticType} from "../../utils/types/ProjectStatistic";
import {DashboardPropsType, DemiaNotification} from "../../utils/types/ProjectDetails";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {SitePlan} from "../SitePlan/SitePlan";
import MolinaSensorMap3D from "../SitePlan/MolinaSitePlan";

const statisticsData = [
  {
    type: "sustainability",
    amount: "",
    unit: "",
    text: "Annual GHG emission reductions"
  },
  {
    type: "sustainability",
    amount: "",
    unit: "",
    text: "GHG emission reductions last 30 day",
  },
  {
    type: "chart",
    amount: "",
    unit: "%",
    text: "Data Confidence",
  },
];


export const Project = () => {
  const [project, setProject] = useState<DashboardPropsType | null>(null);
  const [activity, setActivity] = useState<DemiaNotification[]>([])
  const [statistics, setStatistics] = useState<ProjectStatisticType[]>([{ ...statisticsData[0] }, { ...statisticsData[1] }, { ...statisticsData[2] }])

  const navigate = useNavigate();
  const api = createApiService(config.API_BASE_URL);
  const { slug } = useParams();

  useEffect(() => {
    const getProject = async () => {
      return slug ? await api.context.getSite(slug)
        .then(response => {
            setProject(response)
          }) : new Error('Not a known project id')
    }

    const getNotifications = async () => {
      return await api.context.getNotifications()
        .then(response => {
          const nodes = response.notifications.map((item) => {
            return {
              isRead: false,
              user: item.user,
              timestamp: new Date(item.timestamp),
              message: item.message
            }
          });
          nodes.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
          setActivity(nodes)
        })
    }
    getProject()
      .then( () => getNotifications()
        .catch(err => {
          console.error(err);
          navigate("/overview")
        }))
      .catch(err => {
          console.error(err);
          navigate("/")
        })
  }, [slug])

  const parseValue = (value: string) => {
    const parsed = parseFloat(value);
    return parseFloat(parsed.toFixed(1)).toLocaleString()
  }

  useEffect(() => {
    if (project) {
      const ghgAnnual = {
        type: "sustainability",
        amount: parseValue(project.ghgAnnual.value),
        unit: project.ghgAnnual.unit,
        text: project.ghgAnnual.label,
      }

      const ghg30Day = {
        type: "sustainability",
        amount: parseValue(project.ghg30Day.value),
        unit: project.ghg30Day.unit,
        text: project.ghg30Day.label,
      }


      const dcf = {
        type: "chart",
        amount: project.avgDcf ? project.avgDcf : '0',
        unit: '%',
        text: "Data Confidence"
      }

      setStatistics([ghgAnnual, ghg30Day, dcf])
    }
  }, [project]);

  const mapDataToProjectInfo = () => {
    if(project && project.info && project.info) {
      const {projectId, type, methodology, projectDev} = project.info;
      return {
        projectInfo: {
          projectId,
          type,
          methodology,
          projectDev,
        },
        location: project.location
      }

    } else {
      return {
          projectInfo: {
            projectId: '',
            type: '',
            methodology: '',
            projectDev: '',
          },
          location: {
            address: '',
            lat: 0.0,
            lon: 0.0,
          }
      }
    }
  }

  return (
    <>
      <div className={classes.project}>
      <div>
          <h1 className={classes.title}>{project ? project.projectName : <Skeleton/>}</h1>
          <div className={classes.content}>
            <div className={classes.innerContent}>
              <div className={classes.topContent}>
                <ProjectInfo { ...mapDataToProjectInfo()}/>
                {activity ? <RecentActivity activity={activity}/> : <Skeleton/>}
              </div>
              <div className={classes.statisticWrapper}>
                {statistics.map((i: any, index: any) => (
                    <ProjectStatistic key={index} statistic={i}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {project ? (project.info.projectId === "BEM" ? <MolinaSensorMap3D />: <SitePlan />) : (<Skeleton />) }
    </>
  )
};

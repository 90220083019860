import { Link, useLocation, useParams } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import classes from "./AnalyticsDetails.module.scss";
import { ReactSVG } from "react-svg";
import { arrowLeft, formula } from "../../assets";
import {
  GreenAreaAnalyticsChart,
  PinkLineChart,
} from "../../components/Graphs/Graphs";
import { useEffect, useState } from "react";
import { createApiService } from "../../utils/api";
import { config } from "../../constants";
import { ValueSet } from "../../utils/types";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const parametersData = [
  {
    title: "E SSR P8",
    text: "Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period",
    input: false,
    value: "20",
  },
  {
    title: "E SSR P8",
    text: "Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period",
    input: true,
    value: "20",
  },
  {
    title: "E SSR P8",
    text: "Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period",
    input: true,
    value: "20",
  },
  {
    title: "E SSR P8",
    text: "Total SSR P8 GHG emissions from fossil fuel combustion",
    input: true,
    value: "20",
  },
  {
    title: "E SSR P8",
    text: "Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period",
    input: true,
    value: "",
  },
];

export const AnalyticsDetails = () => {
  const { analyticsSlug, slug } = useParams();
  const url = `/projects/${slug}/analytics`;
  const location = useLocation();

  const api = createApiService(config.API_BASE_URL);
  const [analytics, setAnalytics] = useState<ValueSet | null>(null);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [startDate, _setStartDate] = useState(thirtyDaysAgo);
  const [endDate, _setEndDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    api.context.getAnalyticsFor(slug!, analyticsSlug!).then((value) => {
      if (value) {
        setAnalytics(value)
      }
    })
  }, [location]);

  return (
    <Layout>
      <div className={classes.titleContainer}>
        <Link to={url} className={classes.backLink}>
          <ReactSVG src={arrowLeft} className={classes.arrowIcon}></ReactSVG>
          {analytics?.title || <Skeleton/>}
        </Link>
      </div>
      <div className={classes.content}>
        <div className={classes.innerContent}>
          <div className={classes.analyticsGreenChart}>
            <div className={classes.heading}>
              <h3>{analytics?.title || <Skeleton width={100}/>}</h3>
              <div>
                <h2>{analytics ? Math.round(analytics.total*1000)/1000 : <Skeleton/>}</h2>
                <span>{analytics?.label || <Skeleton width={50}/>}</span>
              </div>
            </div>
            { analytics ? (analyticsSlug === "e_project" ? (
              <PinkLineChart graphData={Object.values(analytics?.values || [])
                .map((val, index) => {
                return { name: analytics!.timestamps[index], uv: val, pv: val, amt: val}
              })} height={245} start={startDate} end={endDate} />
            ) : (
              <GreenAreaAnalyticsChart graphData={Object.values(analytics?.values || [])
                .map((val, index) => {
                return { name: analytics!.timestamps[index], uv: val, pv: val, amt: val}
              })} height={245} start={startDate} end={endDate} />
            ))
            : <Skeleton height={245}/>
            }
          </div>
          <figure className={classes.imageContainer}>
            <img src={formula} alt="Formula" />
          </figure>
          <div className={classes.data}>
            {parametersData.map((item, index) => (
              <div className={classes.singleItemInput} key={index}>
                <div className={classes.left}>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
                {item.input ? (
                  <div className={classes.right}>
                    <span>Value</span>
                    <input type="text" name="value" value={item.value} disabled/>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

import { ReactSVG } from "react-svg";
import { ProjectDetailsPropsType } from "../../utils/types";
import classes from "./ProjectDetails.module.scss";
import { Button } from "../Buttons/Button";
import { clockIcon } from "../../assets";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {GoogleMaps} from "./GoogleMaps";

export const ProjectDetailSkeleton = () => {
  return (
    <article className={classes.projectDetails}>
      <div className={classes.map} >
        <div style={{ height: '280px', width: '40vw'}} />
      </div>
      <section>
        <h3 className={classes.projectName}><Skeleton/></h3>
        <div className={classes.projectInfo}>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p><Skeleton width={70}/></p>
          </div>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p><Skeleton width={70}/></p>
          </div>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p><Skeleton width={70}/></p>
          </div>
        </div>
    </section>
    </article>
  );
};

export const ProjectDetails: React.FC<ProjectDetailsPropsType> = (projectDetails) => {
  return (
    <article className={classes.projectDetails}>
      <div style={{ height: '280px', width: '40vw'}}>
        <GoogleMaps {...projectDetails.location} />
      </div>
      <section>
        <h3 className={classes.projectName}>{projectDetails.projectName}</h3>
        <div className={classes.projectInfo}>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p>{projectDetails.location.address}</p>
          </div>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p>
              {projectDetails.loading ?
                <>
                  Loading site data..
                </> :
                <>
                  {projectDetails.sensorsOnline} Sensors Online
                </>}
            </p>
          </div>
          <div className={classes.info}>
            <ReactSVG className={classes.icon} src={clockIcon} />
            <p>{projectDetails.notifications.length} Notifications</p>
          </div>
        </div>
        <Button isLink href={`/projects/${projectDetails.projectId}`} className={classes.button}>
          View Dashboard
        </Button>
      </section>
    </article>
  );
};

import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import classes from "./AddSensor.module.scss";
import "../Analytics/CalendarStyle.scss";
import { useState } from "react";
import { createApiService } from "../../utils/api";
import { config } from "../../constants";
import { EquipmentDashboardContext } from "../../utils/types/ProjectDetails";

export const AddSensor = () => {
  const { slug } = useParams();

  const navigate = useNavigate();
  const api = createApiService(config.API_BASE_URL);
  const [loading, setLoading] = useState<boolean>(false);

  const addSensorHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    if (loading) {
      return;
    }
    event.preventDefault();
    setLoading(true);

    const target = event.target as typeof event.target & {
        id: { value: string };
        group: { value: string };
        units: { value: string };
        eqType: { value: string };
        name: { value: string };
        accuracy: { value: string };
        installed: { value: string };
        serialNo: { value: string };
        manufacturer: { value: string };
      };
  
    const newSite: EquipmentDashboardContext = {
      id: target.id.value,
      group: target.group.value,
      units: target.units.value,
      eqType: target.eqType.value,
      name: target.name.value,
      accuracy: parseFloat(target.accuracy.value),
      installed: parseFloat(target.installed.value),
      serialNo: target.serialNo.value,
      manufacturer: target.manufacturer.value
    };

    api.context.addSensor(`${slug}`, newSite)
      .then((response) => {
        console.debug("Response from addProject: ", response);
        navigate(`/projects/${slug}/data-sources`);
      })
      .catch((error) => {
        console.error("Error adding sensor:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <Layout>
      <div className={classes.addSensor}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>Add Sensor to {slug}</h1>
        </div>
        <div className={classes.content}>
          <div className={classes.innerContent}>
            <form onSubmit={addSensorHandler} className={classes.form}>
            <div className={classes.input}>
                <label htmlFor="id">id</label>
                <input type="text" name="id" id="id" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="group">group</label>
                <input type="text" name="group" id="group" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="units">units</label>
                <input type="text" name="units" id="units" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="eqType">eqType</label>
                <input type="text" name="eqType" id="eqType" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="name">name</label>
                <input type="text" name="name" id="name" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="accuracy">accuracy</label>
                <input type="number" name="accuracy" id="accuracy" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="installed">installed</label>
                <input type="number" name="installed" id="installed" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="serialNo">serialNo</label>
                <input type="text" name="serialNo" id="serialNo" required />
            </div>
            <div className={classes.input}>
                <label htmlFor="manufacturer">manufacturer</label>
                <input type="text" name="manufacturer" id="manufacturer" required />
            </div>
            <button type="submit" className={classes.submitButton}>
              {loading ? "Adding..." : "Add sensor"}
            </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

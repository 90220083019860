//import { Link } from "react-router-dom";
//import { eyeIconOff, eyeIconOn, logo, signIn } from "../../assets";
import { logo, signIn } from "../../assets";
import classes from "./SignIn.module.scss";
import React, {FunctionComponent, useState} from "react";
import { createApiService } from "../../utils/api";
import { config } from "../../constants.ts";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const SignIn: FunctionComponent = () => {
  const [sentRequest, setSentRequest] = useState<boolean>(false);
  const { loginWithRedirect } = useAuth0();

  const navigate = useNavigate();
  const api = createApiService(config.API_BASE_URL);
  api.cache.clear();

  const toggleForm = () => {
    const signInForm = document.getElementById("signInForm");
    const createForm = document.getElementById("createForm");

    if (signInForm && createForm) {
      signInForm.style.display = window.getComputedStyle(signInForm).display === "none"? "block" : "none";
      createForm.style.display = window.getComputedStyle(createForm).display === "none"? "block" : "none";
    }
  }

  const createUserHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userInfo = event.target as typeof event.target & {
      username: { value: string };
      email: { value: string };
      firstName: { value: string };
      lastName: { value: string };
      organization: { value: string };
    }

    const emailBody = generateEmailBody(userInfo)
    setSentRequest(true);
    window.location.href = `mailto:info@demia.net?subject=Request Demo Access&body=${encodeURI(emailBody)}`;
    navigate('/');
  }

  const generateEmailBody = (userInfo: any) => {
    return `Username: ${userInfo.username.value}\nEmail: ${userInfo.email.value}\nFirst Name: ${userInfo.firstName.value}\nLast Name: ${userInfo.lastName.value}\nOrganization: ${userInfo.organization.value}`;
  }
  return (
    <main className={classes.signIn}>
      <article className={classes.left}>
        <div className={classes.logoContainer}>
          <img src={logo} alt=" Logo"/>
        </div>
        <section id="signInForm" className={classes.signInContainer}>
          <div className={classes.innerContent}>
            <h1 className={classes.title}>Sign in to access the dashboard</h1>
            <button className={classes.submitButton} onClick={() => loginWithRedirect()}> Sign In </button>
            <p className={classes.text}>Don’t have an account?</p>
            <p onClick={toggleForm} className={classes.accessText}>
              Request access here
            </p>
          </div>
        </section>
        <section id="createForm" className={classes.createUserContainer}>
          <div className={classes.innerContent}>
            <h1 className={classes.title}>Create a new account</h1>
            <form onSubmit={createUserHandler} className={classes.form}>
              <div className={classes.input}>
                <label htmlFor="username">Username</label>
                <input type="text" name="username" id="username"/>
              </div>
              <div className={classes.input}>
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email"/>
              </div>
              <div className={classes.input}>
                <label htmlFor="firstName">First Name</label>
                <input type="text" name="firstName" id="firstName"/>
              </div>
              <div className={classes.input}>
                <label htmlFor="lastName">Last Name</label>
                <input type="text" name="lastName" id="lastName"/>
              </div>
              <div className={classes.input}>
                <label htmlFor="organization">Organization</label>
                <input type="text" name="organization" id="organization"/>
              </div>
              {/*
              <p className={classes.error}>
                Credentials are not good, please try again.
              </p>
              */}
              {!sentRequest && <button type="submit" className={classes.submitButton}>
                Submit User Info
              </button>}
              {sentRequest && <p className={classes.centered}>
                  Please complete opened form submission email. You will receive a response soon.
              </p>}
              <button type="button" onClick={toggleForm} className={classes.toggleButton}>
                  Sign In
              </button>
            </form>
            {/*
            <p className={classes.text}>Don’t have an account?</p>
            <Link to="mailto:info@digitalmrv.io" className={classes.accessText}>
              Request access here
            </Link>
            */}
          </div>
        </section>
      </article>
      <aside className={classes.right}>
        <img src={signIn} alt="Sign In image"/>
      </aside>
    </main>
  );
};

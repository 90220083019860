import {Link, useNavigate, useParams} from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import classes from "./Analytics.module.scss";
import "./CalendarStyle.scss";
import { ReactSVG } from "react-svg";
import {
  arrowRightIcon,
  exportIconBlack,
  hamburgerIcon,
  settingsIcon,
} from "../../assets";
import {
  GreenAreaAnalyticsChart,
  PinkLineChart,
} from "../../components/Graphs/Graphs";
import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {createApiService} from "../../utils/api";
import {config} from "../../constants";
import {ProjectStateData, ValueSet} from "../../utils/types";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {DashboardPropsType} from "../../utils/types/ProjectDetails.ts";
import {ProjectStatisticType} from "../../utils/types/ProjectStatistic.ts";
import {ProjectStatistic} from "../../components/Project";

const statisticsData = [
  {
    type: "sustainability",
    amount: "",
    unit: "",
    text: "Annual GHG emission reductions"
  },
  {
    type: "sustainability",
    amount: "",
    unit: "",
    text: "GHG emission reductions last 30 day",
  },
  {
    type: "chart",
    amount: "",
    unit: "%",
    text: "Data Confidence",
  },
];


export const Analytics = () => {
  const { slug } = useParams();
  const api = createApiService(config.API_BASE_URL);
  const url = `/projects/${slug}/analytics-setup/`;
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [analytics, setAnalytics] = useState<ProjectStateData | null>(null);
  const [firstChart, setFirstChart] = useState<ValueSet | null>(null);
  const [firstChartTitle] = useState<string>("e_project");

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [statistics, setStatistics] = useState<ProjectStatisticType[]>([{...statisticsData[0]}, {...statisticsData[1]}, {...statisticsData[2]}]);
  const [project, setProject] = useState<DashboardPropsType | null>(null);

  useEffect(() => {
    const getProject = async () => {
      return slug ? await api.context.getSite(slug)
          .then(response => {
            setProject(response)
          }) : new Error('Not a known project id')
    }

    getProject()
        .catch(err => {
          console.error(err);
          navigate("/")
        })
  }, [slug])

  const parseValue = (value: string) => {
    const parsed = parseFloat(value);
    return parseFloat(parsed.toFixed(0)).toLocaleString()
  }

  useEffect(() => {
    if (project) {
      const ghgAnnual = {
        type: "sustainability",
        amount: parseValue(project.ghgAnnual.value),
        unit: project.ghgAnnual.unit,
        text: "Annual GHG emissions reductions (est)",
      }

      const ghg30Day = {
        type: "sustainability",
        amount: parseValue(project.ghg30Day.value),
        unit: project.ghg30Day.unit,
        text: "GHG emissions reductions last 30 days",
      }

      const dcf = {
        type: "chart",
        amount: project.avgDcf ? project.avgDcf : '0',
        unit: '%',
        text: "Data Confidence"
      }

      setStatistics([ghgAnnual, ghg30Day, dcf])
    }
  }, [project]);


  useEffect( () => {
    const getAnalytics = async () => {
      return slug ? await api.context.getAnalytics(slug)
        .then(response => {
          // eProject = project total emissions
          // TODO: Define a user front end dependent state for FirstChart so they can choose which chart rests up top
          const eProject = Object.entries(response).find(([label,],) => label === firstChartTitle);
          setFirstChart(eProject? eProject[1] : null)
          setAnalytics(Object.fromEntries(
            // Filter out calc data
            // TODO: Remove calc data from forwarded state, Records can/should be separate from analytics profile
            Object.entries(response).filter(([label,], ) => label !== 'calc_data')
          ))
        }) : new Error('No analytics response')
    }

    getAnalytics()
      .catch(err => {
        console.error(err);
        navigate("/")
      })
  }, [startDate, endDate])

  const exportButtonHandler = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <Layout>
      <div className={classes.analytics}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>Analytics</h1>
          <div className={classes.rightButtons}>
            <Link to={url} className={classes.button}>
              <ReactSVG src={settingsIcon} className={classes.icon}></ReactSVG>
              Settings
            </Link>
            <div className={classes.datePickerContainer}>
              <div className={classes.datepicker}>
                <DatePicker
                  calendarClassName='calendarStyle'
                  popperClassName="pooperStyle"
                  selected={startDate}
                  onChange={(date) => date && setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM d, yyyy"
                />
                <span>-</span>
                <DatePicker
                  calendarClassName='calendarStyle'
                  popperClassName="pooperStyle"
                  selected={endDate}
                  onChange={(date) => date && setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
              <span className={classes.divider}></span>
              <div
                className={classes.moreOptions}
                onClick={exportButtonHandler}
              >
                <ReactSVG
                  src={hamburgerIcon}
                  className={classes.hamburgerIcon}
                ></ReactSVG>
              </div>
            </div>
          </div>
          {isVisible && (
            <div className={classes.export}>
              <div className={classes.exportWrapper}>
                <h4>Export</h4>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.icon}
                  ></ReactSVG>
                  Export to CSV
                </button>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.icon}
                  ></ReactSVG>
                  Export to XLSV
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.innerContent}>
            {/* first main chart here */}
            <div className={classes.analyticsGreenChart}>
              <div className={classes.heading}>
                  <div className={classes.projectLink}>
                    {firstChart ? <>
                    <Link to={firstChartTitle}>{firstChart.title}</Link>
                    <ReactSVG src={arrowRightIcon} className={classes.icon} />
                    </> : <Skeleton />}
                </div>
                <div>
                  <h2>{firstChart?.total.toFixed(3) || <Skeleton />}</h2>
                  <span>{firstChart?.label || <Skeleton />}</span>
                </div>
              </div>
              {firstChart ?
                <PinkLineChart graphData={Object.values(firstChart.values)
                  .map((val, index) => {
                    return { name: firstChart.timestamps[index], uv: val, pv: val, amt: val }
                  })}
                  start={startDate}
                  end={endDate}
                  height={245}
                />
                : <>
                  <div className={classes.heading}>
                    <h3><Skeleton baseColor="white"/></h3>
                  </div>
                  <Skeleton height={245} baseColor="white"/>
                </>}
            </div>
            {/* 3 elements */}
            <div className={classes.statisticWrapper}>
              {statistics.map((i: any, index: any) => (
                  <ProjectStatistic key={index} statistic={i}/>
              ))}
            </div>
            {/* chart grid */}
            <div className={classes.grid}>
              {analytics && Object.entries(analytics)
                .filter(([label,], ) => label !== firstChartTitle)
                .map((([id, item], index) => (
                <div className={classes.analyticsGreenChart} key={index}>
                  <div className={classes.heading}>
                    <div className={classes.projectLink}>
                      <Link to={id}>{item.title}</Link>
                      <ReactSVG src={arrowRightIcon} className={classes.icon} />
                    </div>
                    <div>
                      <h2>{item.total.toFixed(3)}</h2>
                      <span>{item.label}</span>
                    </div>
                  </div>
                  <GreenAreaAnalyticsChart
                    graphData={Object.values(item.values)
                        .map((val, index) => {
                        return { name: item.timestamps[index], uv: val, pv: val, amt: val}
                      })}
                    height={197}
                    start={startDate}
                    end={endDate}
                  />
                </div>
              )))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

import { Link, useLocation, useParams } from "react-router-dom";
import classes from "./CargoSensor.module.scss";
import { ReactSVG } from "react-svg";
import {
  arrowLeft,
  greenWaste,
  grapeHarvest,
  cargoAverage,
  cargoCondidence,
  cargoCurrent,
  exportIconBlack,
  hamburgerIcon,
} from "../../assets";
import { Layout } from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import {PinkBarChart} from "../../components/Graphs/Graphs";
import { TableSorting } from "../../components/Table/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Analytics/CalendarStyle.scss";
import {SensorDashboardContext} from "../../utils/types/ProjectDetails";
import { timeToReadable } from "../../utils/display";
import { ReadingData } from "../../utils/types";
import {ReadingRowTemplate, ReadingFields, sortFns, readingContextToData} from "../../components/Table/ReadingsTable"
import Skeleton from "react-loading-skeleton";

/*const loadInfo = {
  source: "2023 Ford Transit Van #13",
  lastUpdate: "10/11/2023",
  unit: "2,4 kg",
};*/

var cargoDataEmpty: Array<{
  img: string,
  text: string,
  value: number,
  valueUnit: string,
}> = [
  {
    img: cargoCurrent,
    text: "Current load",
    value: 0,
    valueUnit: "",
  },
  {
    img: cargoAverage,
    text: "Daily average load",
    value: 0,
    valueUnit: "",
  },
  {
    img: cargoCondidence,
    text: "Data confidence",
    value: 0,
    valueUnit: "%",
  },
];

export const CargoSensor: React.FC<{ sensor: SensorDashboardContext | undefined; }> = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex: number) => {
    if (tabIndex == 1) {
      setIsVisible(false);
    }
    setActiveTab(tabIndex);
  };

  const { slug } = useParams();
  const url = `/projects/${slug}/data-sources`;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location]);

  const [isVisible, setIsVisible] = useState(false);

  const exportButtonHandler = () => {
    setIsVisible((prev) => !prev);
  };

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [sensor, setSensor] = useState <SensorDashboardContext>();
  const [cargoData, setCargoData] = useState <Array<{
    img: string,
    text: string,
    value: number,
    valueUnit: string,
  }>>([{ ...cargoDataEmpty[0] }, { ...cargoDataEmpty[1] }, { ...cargoDataEmpty[2] }]);


  const [tableList, setTableList] = useState <Array<ReadingData>>([]);

  const [graphData, setGraphData] = useState <Array<{
    name: string,
    uv: number,
    pv: number,
    amt: number,
  }>>([]);

  useEffect(() => {
    if (sensor) {
      setGraphData(Array.from(Object.values(sensor.sensor.readings)).map((v) => {
        return {
          name: v.timestamp,
          uv: v.value,
          pv: v.value,
          amt: v.value,
        }
      }));

      const readings = Array.from(Object.values(sensor.sensor.readings)).map((v) => {
        return readingContextToData(v, sensor.sensor.equipment.units)
      });

      readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      setTableList(readings);
    }
  }, [sensor]);

  useEffect(() => {
    if (props.sensor) {
      setSensor(props.sensor);
      let numbers = [...cargoData];
      numbers[0].value = Math.round(props.sensor.stateData.realTimeFlow);
      numbers[0].valueUnit = props.sensor.sensor.equipment.units;
      numbers[1].value = Math.round(props.sensor.stateData.currentDayAvg);
      numbers[1].valueUnit = props.sensor.sensor.equipment.units;
      numbers[2].value = Math.round(props.sensor.sensor.avgcf);
      setCargoData(numbers);
    }
  }, [props.sensor]);

  return (
    <Layout>
      <div className={classes.cargoSensor}>
        <div className={classes.titleContainer}>
          <Link to={url} className={classes.backLink}>
            <ReactSVG src={arrowLeft} className={classes.arrowIcon}></ReactSVG>
            { sensor ? sensor.sensor.equipment.name : "Cargo sensor" }
          </Link>
            <div className={classes.datePickerContainer}>
              <div className={classes.datepicker}>
                <DatePicker
                  calendarClassName="calendarStyle"
                  popperClassName="pooperStyle"
                  selected={startDate}
                  onChange={(date) => date && setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMMM d, yyyy"
                />
                <span>-</span>
                <DatePicker
                  calendarClassName="calendarStyle"
                  popperClassName="pooperStyle"
                  selected={endDate}
                  onChange={(date) => date && setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MMMM d, yyyy"
                />
                <span className={classes.divider}></span>
            
              {activeTab === 2 && (
                  <div
                    className={classes.moreOptions}
                    onClick={exportButtonHandler}
                  >
                    <ReactSVG
                      src={hamburgerIcon}
                      className={classes.hamburgerIcon}
                    ></ReactSVG>
                  </div>
              )}
              </div>
          </div>
          {isVisible && (
            <div className={classes.export}>
              <div className={classes.exportWrapper}>
                <h4>Export</h4>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.exportIcon}
                  ></ReactSVG>
                  Export to CSV
                </button>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.exportIcon}
                  ></ReactSVG>
                  Export to XLSV
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={classes.tabs}>
          <div
            className={
              activeTab === 1
                ? `${classes.tab} ${classes.active}`
                : `${classes.tab}`
            }
            onClick={() => handleTabClick(1)}
          >
            Overview
          </div>
          <div
            className={
              activeTab === 2
                ? `${classes.tab} ${classes.active}`
                : `${classes.tab}`
            }
            onClick={() => handleTabClick(2)}
          >
            Log
          </div>
        </div>
        {activeTab === 1 && (
          <article className={classes.cargoInfoWrapper}>
            <div className={classes.cargoInfo}>
              <figure className={classes.image}>
                {sensor ?
                  (sensor.sensor.id === "Grape Harvest" ?
                    <img src={grapeHarvest} alt="grapeHarvest" /> : <img src={greenWaste} alt="greenWaste" />
                  ) : (
                    <Skeleton/>
                  )
                }
              </figure>
              <div className={classes.rightSide}>
                <div className={classes.rightTop}>
                  {cargoData.map((data, index) => (
                    <div className={classes.singleItem} key={index}>
                      <img src={data.img} alt="Image" />
                      <p>{data.text}</p>
                      <h5>
                        {data.value} <span>{data.valueUnit}</span>
                      </h5>
                    </div>
                  ))}
                </div>
                <div className={classes.rightBottom}>
                  <h4>Solid Waste Load</h4>
                  <div className={classes.loadInfo}>
                    <p>
                      Source: <span>{sensor?.sensor.equipment.id}</span>
                    </p>
                    <p>
                      Last update: <span>{ sensor ? timeToReadable(new Date(sensor.sensor.lastUpdated)) : "never"}</span>
                    </p>
                    <p>
                      Units: <span>{sensor?.sensor.equipment.units}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.graph}`}>
              <PinkBarChart graphData={graphData} start={startDate} end={endDate} />
            </div>
          </article>
        )}
        {activeTab === 2 && (
          <div className={classes.log}>
            <TableSorting
              className={classes.table}
              title={(sensor?.sensor.total || 0) + " Records"}
              data={{ nodes: tableList }}
              fields={ReadingFields}
              RowTemplate={ReadingRowTemplate}
              sortFns={sortFns}
              limit={10}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

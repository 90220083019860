import { BaseApiService } from './BaseApiService';
import {
    DashboardPropsType,
    OverviewPropsType,
    NewProjectRequest,
    EquipmentDashboardContext,
    SensorDashboardContext,
    SensorsDashboardContextItem,
    DemiaNotificationProps
} from "../types/ProjectDetails.ts";
import { ProjectStateData, ValueSet } from '../types'
import { FileType, ListFilesContext } from '../types/File.ts';

export class ContextService extends BaseApiService {
    constructor(baseUrl: string) {
        super(baseUrl);
    }

    async getProjects(redirect: boolean = true): Promise<OverviewPropsType> {
        return this.get('api/context/overview', undefined, redirect)
    }

    async getSensors(id: string): Promise<SensorsDashboardContextItem[]> {
        const url = 'api/context/' + id + '/sensors2'
        return this.get(url)
    }

    async getSensor(siteId: string, sensorId: string): Promise<SensorDashboardContext> {
        const url = 'api/context/' + siteId + '/sensors2/' + sensorId
        return this.get(url)
    }

    async getSite(id: string): Promise<DashboardPropsType> {
        const url = 'api/context/' + id + '/dashboard'
        return this.get(url)
    }

    async addSite(project: NewProjectRequest): Promise<void> {

        return this.post('api/context/new_stream', project)
    }

    async addSensor(id: string, sensor: EquipmentDashboardContext): Promise<void> {

        return this.post(`api/context/new_sensor/${id}`, sensor)
    }

    async getAnalytics(id: string): Promise<ProjectStateData> {
        const url = 'api/context/' + id + '/analytics2'
        return this.get(url)
    }
    async getAnalyticsFor(id: string, analyticsId: string): Promise<ValueSet> {
        const url = 'api/context/' + id + '/analytics/' + analyticsId;
        return this.get(url)
    }
    async getNotifications(): Promise<DemiaNotificationProps> {
        return this.get('api/context/notifications')
    }

    async uploadFile(site: string, files: File[]): Promise<ListFilesContext> {
        const url = 'api/context/' + site + '/upload';
        const formData = new FormData();
        [...files].forEach((file) => {
            formData.append("files", file);
        });

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            return this.post(url, formData, undefined)
        } catch (e: any) {
            console.error(e);
            return Promise.reject()
        }
    }

    async downloadFile(site: string, name: string): Promise<Blob> {
        const url = 'api/context/' + site + '/files/' + name;
        return await this.get(url);
    }

    async deleteFile(site: string, name: string): Promise<void> {
        const url = 'api/context/' + site + '/files/' + name;
        return this.delete(url)
    }

    async listFiles(site: string): Promise<ListFilesContext> {
        const url = 'api/context/' + site + '/files';
        return this.get(url)
    }

    async filesMetadata(site: string, _files: FileType[]): Promise<ListFilesContext> {
        const url = 'api/context/' + site + '/files/metadata';
        return this.get(url);
    }
}
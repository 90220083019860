import { ReactSVG } from "react-svg";
import classes from "./Header.module.scss";
import {notificationsIcon} from "../../assets";
import { SearchBox } from "./SearchBox/SearchBox";
import { Link, useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import { SettingsAccordion } from "./Settings/SettingsAccordion";
//import {useCookies} from 'react-cookie';
import {useAuth0} from "@auth0/auth0-react";


export const Header = () => {
    const { isAuthenticated, user } = useAuth0();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [initials, setInitials] = useState<string>("");

    useEffect(() => {
        if (isAuthenticated && user && user.name) {
            let firstLetter = user?.name.toUpperCase()[0];
            let secondLetter = user?.family_name ? user?.family_name[0] : user?.name.toLowerCase()[1];
            setInitials([firstLetter, secondLetter].join(""));
        }
    })
    const toggleAccordion = () => setIsOpen((prev) => !prev);

    const { slug } = useParams();
    const url = `/projects/${slug}/notifications`;

    return (
      <header className={classes["header"]}>
          <div className={classes["header__icons"]}>
              <Link to={url}>
                  <ReactSVG
                      className={classes["notifications"]}
                      wrapper="span"
                      src={notificationsIcon}
                  ></ReactSVG>
              </Link>
              <SearchBox onChange={() => null}></SearchBox>
          </div>
          <div className={classes["user"]} onClick={toggleAccordion}>{initials}</div>
          {isOpen && <SettingsAccordion />}
      </header>
    );
};

import { format } from 'date-fns'

/**
 * Format bytes as human-readable text.
 * If size is not a number, returns original size.
 * 
 * @param size Number of bytes in a string.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export function sizeToReadable(size: string, si=true, dp=1): string {
    try {
        let bytes = parseInt(size);
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si 
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    } catch (e) {
        return size;
    }
}

export function timeToReadable(date: Date): string {
    try {

        const now = new Date();

        // TODO: Address the system time issue with the simulator
        if (date.getTime() > now.getTime()) {
            date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        }

        const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000);

        if (secondsAgo < 60) {
            return `${secondsAgo} seconds ago`;
        }
    
        const minutesAgo = Math.floor(secondsAgo / 60);
        if (minutesAgo < 60) {
            return `${minutesAgo} minutes ago`;
        }
    
        const hoursAgo = Math.floor(minutesAgo / 60);
        if (hoursAgo < 24) {
            return `${hoursAgo} hours ago`;
        }
    
        // More than a day ago
        return format(date, 'MMM/dd HH:mm:ss');
    } catch (e) {
        console.error(e);
        return "" + date;
    }
}
import {createBrowserRouter, NavigateFunction, RouterProvider, useNavigate} from "react-router-dom";
import {
  //Activity,
  AddStream,
  AllProjects,
  Analytics,
  AnalyticsDetails,
  AnalyticsSetup,
  Dashboard,
  Documentation,
  Identity,
  Notifications,
  Parameters,
  Profile,
  Sensors,
  SensorTemplates,
  SignIn,
  Users,
} from "./pages";
import products from "./assets/json/projects.json";
import { IProject } from "./utils/types/Project";
import { AddSensor } from "./pages";
import { ErrorBoundaryLayout } from "./ErrorBoundary";
import { CookiesProvider } from "react-cookie";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {createContext, ReactNode, useEffect, useState} from "react";
import {createApiService} from "./utils/api";
import {config} from "./constants.ts";
import {Loading} from "./components/Loading/Loading.tsx";


export const Auth0ProviderWithRedirectCallback: React.FC<{children: ReactNode}> = ({ children }) => {
    const onRedirectCallback = (appState: any) => {
        console.log(appState);
        console.log("Login was registered successfully");
    };

    return (
        <Auth0Provider
            onRedirectCallback={onRedirectCallback}
            domain={config.AUTH0_DOMAIN}
            clientId={config.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            useRefreshTokens={true}
            cacheLocation={"localstorage"}
        >
            {children}
        </Auth0Provider>
    );
};
const UserContext = createContext({state: {}, actions: {}});

const UserProvider: React.FC<{children: ReactNode}> = ({ children }) => {
    const {user, isAuthenticated, isLoading, getIdTokenClaims} = useAuth0();
    const [fetching, setFetching] = useState(false);

    const api = createApiService(config.API_BASE_URL);

    let navigate: NavigateFunction | null = null;
    try {
        navigate = useNavigate();
    } catch(e) {
        console.error(e);
        return (
            <UserContext.Provider value={{state: {}, actions: {}}}>
                {children}
            </UserContext.Provider>
        )
    }

    const getToken = async () => {
        if (isAuthenticated && !fetching) {
            setFetching(true);
            api.auth.status()
                .then(status => {
                    if (!status) {
                        getIdTokenClaims()
                            .then((t) => {
                                let token = t ? t.__raw : "";
                                api.auth.login(user?.email!, token, token)
                                    .then(() => {
                                        // If were here it means login went well
                                        // And we tried to navigate to /
                                        //navigate!('/overview')
                                        setFetching(false);
                                    })
                                    .catch((error) => {
                                        setFetching(false);
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                setFetching(false);
                                console.log(error);
                                window.location.reload();
                            });
                    } else {
                        setFetching(false);
                        navigate ? navigate('/overview') : window.location.reload();
                    }
                })
                .catch(e => {
                    console.error("Error ", e);
                    console.log("No status");
                    if (isAuthenticated && user?.email !== undefined) {
                        navigate ? navigate('overview') : window.location.reload();
                    } else {
                        navigate ? navigate('') : window.location.reload();
                    }
                });
        }

        if (!fetching) {
            return (
                <UserContext.Provider value={{state: {}, actions: {}}}>
                    {children}
                </UserContext.Provider>
            )
        } else {
            return (
                <UserContext.Provider value={{state: {}, actions: {}}}>
                    <Loading/>
                </UserContext.Provider>
            )
        }
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            getToken()
                .catch((error) => {
                    console.log(error);
                    window.location.reload();
                });
        }
    }, [isAuthenticated]);


    if (isAuthenticated && user?.email !== undefined) {
        return (
            <UserContext.Provider value={{state: {}, actions: {}}}>
                {fetching ? (<Loading/>) : children}
            </UserContext.Provider>
        )
    } else {
        if (isLoading) {
            //window.location.reload();
            if (fetching) {
                return (
                    <UserContext.Provider value={{state: {}, actions: {}}}>
                        <Loading/>
                    </UserContext.Provider>
                )
            }
        }
        return (
            <UserContext.Provider value={{state: {}, actions: {}}}>
                {children}
            </UserContext.Provider>
        )
    }
}



const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      { path: "", element: <UserProvider><SignIn /></UserProvider> },
      {
        path: "projects/:slug",
        loader: async ({ params }) => {
          return {
            data: (products as unknown as IProject[]).find(
              (project: IProject) => project.slug === params.slug
            ),
            slug: params.slug,
          };
        },
        children: [
          { path: "", element: <Dashboard />},
          { path: "activity", element: <Notifications /> },
          { path: "add-sensor", element: <AddSensor /> },
          { path: "users", element: <Users /> },
          { path: "notifications", element: <Notifications /> },
          { path: "documentation", element: <Documentation /> },
          { path: "users", element: <Users /> },
          { path: "data-sources/", element: <Sensors /> },
          { path: "data-sources/:sensorsSlug", element: <SensorTemplates /> },
          { path: "analytics", element: <Analytics /> },
          { path: "analytics/:analyticsSlug", element: <AnalyticsDetails /> },
          { path: "analytics-setup", element: <AnalyticsSetup /> },
          { path: "analytics-setup/:analyticsSetupSlug",element: <Parameters /> },
        ],
      },
      { path: "add-stream", element: <AddStream /> },
      { path: "profile", element: <Profile /> },
      { path: "identity", element: <Identity /> },
      { path: "overview", element: <UserProvider><AllProjects /></UserProvider> },
    ]
  }
]);

const App = () => {
    const { isLoading, error } = useAuth0();

    if (isLoading) {
        return <Loading/>
    }

    if (error) {
        console.error("Error ", error);
    }


    return (
            <CookiesProvider defaultSetOptions={{path: '/'}}>
                <RouterProvider router={router}/>
            </CookiesProvider>
    );
};

export default App;

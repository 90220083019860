import {useEffect, useState} from "react";
import classes from "./Identity.module.scss";
import { Button } from "../../components/Buttons/Button";
import {createApiService} from "../../utils/api";
import {config} from "../../constants.ts";
import { Layout } from "../../components/Layout/Layout.tsx";

export const Identity = () => {
  const api = createApiService(config.API_BASE_URL);

  const [loading, setLoading] = useState(true);
  const [identity, setIdentity] = useState<any | null>(null);
  const [message, setMessage] = useState<string>('');
  const [deleteDid, setDeleteDid] = useState(false);
    
  const getIdentity = async () => {
    await api.user.getIdentity(false)
        .then((response) => {
            if (response && response["doc_id"]) {
                setMessage('');
                setIdentity(response["doc_id"]);
            }
      })
      .catch((error) => {
          setMessage(error.toString());
          setDeleteDid(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }
    useEffect(() => {
        getIdentity()
    }, []);

  const createIdentity = async () => {
      setMessage('Processing Request... Please wait');
      await api.user.createIdentity()
        .then((response) => {
            setMessage('');
            setIdentity(response);
        })
        .catch((error) => {
            setMessage(error.toString());
        });
  }
    
  const deleteIdentity = async () => {
    setMessage('Processing Request... Please wait');
    await api.user.deleteIdentity()
        .then((_) => {
            setMessage('');
            setDeleteDid(false);
            setIdentity(null);
      })
      .catch((error) => {
            setMessage(error.toString());
      });
}

  return (
      <Layout>
          <div className={classes.identity}>
              {identity && <div id="identityContainer" className={classes.identityCard}>
                  <div className={classes.antCardBody}>
                      <div className={classes.titleContainer}>
                          <h1 className={classes.title}>Identity</h1>
                      </div>
                      <div className={classes.content}>
                        <p> An identity that exists </p>
                        <div className={classes.message}>{"" + identity.toString()}</div>
                      </div>
                      <div className={classes.content}>
                        <Button onClick={deleteIdentity} className={classes.button}>Delete</Button>
                      </div>
                  </div>
              </div>}
              {!identity &&
                  <div id="createIdentityContainer" className={classes.identityCard}>
                      <div className={classes.antCardBody}>
                          <div className={classes.titleContainer}>
                              <h1 className={classes.title}>Create an Identity for your Account</h1>
                          </div>
                          <div className={classes.content}>
                              {!deleteDid && <Button disabled={loading} onClick={createIdentity} className={classes.button}>Create</Button>}
                              {deleteDid && <Button onClick={deleteIdentity} className={classes.button}>Delete</Button>}
                              <div className={classes.message}>{message}</div>
                          </div>
                      </div>
                  </div>
              }
          </div>
      </Layout>
  );
};

// All env vars need to be prepended with VITE_ on the system
// Disabled due to: vite.config.ts: __APP_ENV__
// WARN: Potential security issue
export const config = {
    DEMO_MODE: strToBool(true, import.meta.env.VITE_DEMO_MODE),
    DEVELOPER_MODE: import.meta.env.DEV || false,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000',
    EXPLORER_URL: import.meta.env.VITE_EXPLORER_URL || 'http://54.173.103.36:8081/dashboard/explorer',
    AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN || 'https://signin.demo.demia.net',
    AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID || 'KJO1MMQW7ae5aQykrpbNKZnyUJb7dsyZ',
};

function strToBool(fallback: boolean, value?: string): boolean {
    switch (value?.toLocaleLowerCase()){
        case "true": return true;
        case "false": return false;
        default: return fallback
    }
}
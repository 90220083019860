import {Link, useParams} from "react-router-dom";
import classes from "./RecentActivity.module.scss";
//import { ActiveUser } from ".";
import { FunctionComponent } from "react";
//import { IProjectActivity } from "../../utils/types/Project";
import {DemiaNotification} from "../../utils/types/ProjectDetails";
import {NotificationBlob} from "./ActiveUser";
export const RecentActivity: FunctionComponent<{activity: DemiaNotification[]}> = ({activity = []}) => {
  const loaderSlug = useParams().slug;
  //const loaderSlug: string = (useMatches()[0].data as { data: IProject, slug: string}).slug;
  return (
    <section className={classes.recentActivity}>
      <div className={classes.title}>
        <h3>Recent Activity</h3>
        <Link to={`/projects/${loaderSlug}/notifications`}>View all</Link>
      </div>
      <div className={classes.users}>
        {activity.map((i: DemiaNotification, index: number) => (
          <div>
            <NotificationBlob key={index} {...i} />
          </div>
        ))}
      </div>
    </section>
  );
};

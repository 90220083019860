import React, {useState} from "react";
import classes from "./Stripe.module.scss";
import {useStripe} from "@stripe/react-stripe-js";
import {config} from "../../constants.ts";
import {createApiService} from "../../utils/api";
import {logo} from "../../assets";
import {StripeEmbeddedCheckout} from "@stripe/stripe-js";
import {ProjectDetailsPropsType} from "../../utils/types";


interface StripeProduct {
    productId: string;
    feeId: string;
    name: string;
    price: number
    feePrice: number;
}

export const products: StripeProduct[] = [
    {productId: "prod_QV2sO44siTfL4e", feeId: "prod_QXeDm2I8Ac5mSV", name: "Basic", price: 100, feePrice: 10000},
    {productId: "prod_QWxpKwK0W2nSiM", feeId: "prod_QXeDm2I8Ac5mSV", name: "Advanced", price: 200, feePrice: 10000},
]

export const StripePricingTable: React.FC<{ customerId: string, projectId: string }> = (customerProps) => {
    const stripe = useStripe();
    const api = createApiService(config.API_BASE_URL);

    const [loading] = useState<boolean>(stripe === null);
    const [error, setError] = useState<string | null>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean[]>(products.map(() => false));
    const [checkoutComponent, setCheckoutComponent] = useState<StripeEmbeddedCheckout | null>(null);
    const updateButtonLoading = (index: number, value: boolean) => {
        const buttonLoadingCopy = [...buttonLoading];
        buttonLoadingCopy[index] = value;
        setButtonLoading(buttonLoadingCopy);
    }

    const handleCheckout = async (product: StripeProduct, index: number) => {
        updateButtonLoading(index, true);
        setError(null);

        checkoutComponent?.unmount();
        setCheckoutComponent(null);

        api.auth.createPaymentIntent(
            customerProps.customerId,
            product.productId,
            product.feeId,
            window.location.href,
            customerProps.projectId
        ).then((response) => {
                if (response.sessionId) {
                    stripe?.initEmbeddedCheckout({clientSecret: response.sessionId})
                        .then((checkout) => {
                            checkout.mount("#checkout");
                            setCheckoutComponent(checkout);
                        })
                        .catch((err) => {
                            setError("Checkout not created. " + err.message);
                        });
                } else {
                    setError("Session Id not created for checkout");
                }
            })
            .catch((err) => {
                console.error(err);
                setError("Checkout not created. " + err.message);
            })
            .finally(() => {
                updateButtonLoading(index, false);
            });
    }

    return (
        <div>
            <div className={classes.checkoutMount} id={"checkout"}></div>
            {checkoutComponent ? (
                    <button className={classes.paymentButton} onClick={() => {
                        checkoutComponent?.unmount();
                        setCheckoutComponent(null)
                    }}>Back
                    </button>
            ) : (
                <div>
                    <div className={classes.productTable}>
                        {!loading && products.map((product, i) => {
                            return (
                                <div key={product.productId} className={classes.product}>
                                    <img src={logo} alt="logo"/>
                                    <h2>{product.name}</h2>
                                    <h3>Integration Fee</h3>
                                    <p>${product.feePrice / 100.00} One Time</p>
                                    <h3>Subscription Price</h3>
                                    <p>${product.price / 100.00} / Month</p>
                                    <button className={classes.paymentButton} disabled={buttonLoading[i]}
                                            onClick={() => handleCheckout(product, i)}>Subscribe
                                    </button>
                                </div>
                            )
                        })}
                        {loading && (
                            <p>We're having trouble loading the product list. Please try refreshing...</p>
                        )}
                    </div>
                    <div className={classes.error}>
                        {error && (
                            <p>Error: {error}</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
};

export const CheckoutForm: React.FC<{ customerId: string, site: ProjectDetailsPropsType }> = (customerProps) => {
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);

    return (
        <div className={classes.checkoutForm}>
            <h2 className={classes.title}>{customerProps.site.projectName}</h2>
            {showPaymentForm && (
                <div>
                    <StripePricingTable customerId={customerProps.customerId} projectId={customerProps.site.projectId}/>
                </div>
            )}
            {!showPaymentForm && (
                <div className={classes.product}>
                    <h2>Upgrade to a production tier (testing)</h2>
                    <button className={classes.paymentButton} onClick={() => setShowPaymentForm(true)}>
                        Subscribe
                    </button>
                </div>
            )}
        </div>
    );
};
import {FunctionComponent} from 'react';
import classes from './SettingsAccordion.module.scss';
import {useNavigate} from 'react-router-dom';
import {createApiService} from "../../../utils/api";
import {config} from "../../../constants.ts";
import {useCookies} from 'react-cookie';
import {useAuth0} from "@auth0/auth0-react";

export const SettingsAccordion: FunctionComponent = () => {
    const { logout } = useAuth0();
    const api = createApiService(config.API_BASE_URL)
    const navigate = useNavigate();

    const [, setCookie] = useCookies(['username']);
    const profile = async () => {
        navigate('/profile')
    }

    const signOut = async () => {
        api.cache.clear();
        await api.auth.logout()
            .catch((error) => {
                console.error('Error signing out:', error);
            });

        setCookie('username', '');
        await logout({ logoutParams: {returnTo : window.location.origin }});
    }

    return (
        <div className={classes['sidebar-accordion']}>
            <div className={
                    classes['sidebar-accordion-body'] +
                    ' ' + classes['active']
                }
            >
                <a className={classes['links']} onClick={profile}>
                    <span>
                        Profile
                    </span>
                </a>
                <a className={classes['links']} onClick={signOut}>
                    <span>
                        Sign Out
                    </span>
                </a>
            </div>
        </div>
    );
};

import { FunctionComponent } from "react";
import classes from './Sidebar.module.scss';
import { activityIcon, analyticsIcon, dashboardIcon, dataSourcesIcon, documentationIcon, logo, usersIcon } from "../../assets";
import { SidebarAcccordion } from "./SidebarAccordion/SidebarAccordion";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import { ReactSVG } from "react-svg";
import {config} from "../../constants.ts";

export const SidebarNavigation: FunctionComponent = () => {
  const loaderSlug = useParams().slug;
  const navigate = useNavigate();
    return (
        <aside className={classes['sidebar']}>
            <div className={classes['logo-and-projects']}>
                <div className={classes['logo']}>
                  <div className={classes.logo}>
                    <a onClick={() => {navigate('/overview')}}>
                      <img src={logo} alt=" Logo"/>
                    </a>
                  </div>
                </div>
                <SidebarAcccordion></SidebarAcccordion>
            </div>
        
            <nav className={classes['navigation']}>
            {
              loaderSlug ? (<>
                <NavLink to={`/projects/${loaderSlug}`} end className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={dashboardIcon}></ReactSVG> Dashboard</NavLink>
                <NavLink to={`/projects/${loaderSlug}/analytics`} className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={analyticsIcon}></ReactSVG> Analytics</NavLink>
                <NavLink to={`/projects/${loaderSlug}/data-sources`} className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['stroke']} src={dataSourcesIcon}></ReactSVG> Data Sources</NavLink>
                <NavLink to={`/projects/${loaderSlug}/documentation`} className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['stroke']} src={documentationIcon}></ReactSVG> Documentation</NavLink>
                <NavLink to={`/projects/${loaderSlug}/notifications`} className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['stroke']} src={activityIcon}></ReactSVG> Activity</NavLink>
                
              {config.DEMO_MODE ? <> </> : <>
                <NavLink to={`/projects/${loaderSlug}/users`} className={({ isActive }) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['stroke']} src={usersIcon}></ReactSVG> Users</NavLink>
                <NavLink to={`/projects/${loaderSlug}/add-sensor`} className={({ isActive }) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['stroke']} src={usersIcon}></ReactSVG> Add Sensor</NavLink>
              </>}
                
              </>) : (config.DEMO_MODE ? <> </> : <>
                  <NavLink to={`/overview`} end className={({ isActive }) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={dashboardIcon}></ReactSVG> All projects</NavLink>
                  <NavLink to={`/add-stream`} end className={({ isActive }) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={dashboardIcon}></ReactSVG> Add project</NavLink>
                  <NavLink to={`/profile`} end className={({isActive}) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={dashboardIcon}></ReactSVG> Profile</NavLink>
                  <NavLink to={`/identity`} end className={({ isActive }) => isActive ? classes['active'] : ''}><ReactSVG wrapper="span" className={classes['fill']} src={dashboardIcon}></ReactSVG> Identity</NavLink>
              </>)
              }
              </nav>
        </aside>
    )
}
import { AuthService } from "./AuthService";
import { UserService } from "./UserService";
import { ContextService } from "./ContextService";
import { Cache } from "./Cache"
export type { BaseApiService } from "./BaseApiService";
export type { Cache } from "./Cache"

export interface ApiService {
    auth: AuthService;
    user: UserService;
    context: ContextService;
    cache: Cache
}

export function createApiService(baseURL: string): ApiService {
    return {
        auth: new AuthService(baseURL),
        user: new UserService(baseURL),
        context: new ContextService(baseURL),
        cache: new Cache()
    }
}

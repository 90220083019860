import { ProjectSlugAccordion } from "../types/ProjectDetails";
import { SensorSource } from "../types/Sensor";

export class Cache {
    private _projects: Array<ProjectSlugAccordion> = []
    private _sensors: Array<SensorSource> = []

    constructor() {
        if (typeof (Storage) !== "undefined") {
            this._projects = JSON.parse(sessionStorage.getItem("projects") || "[]");
            this._sensors = JSON.parse(sessionStorage.getItem("sensors") || "[]");
        }
    }

    public get projects() {
        return this._projects
    }
    public get sensors() {
        return this._sensors
    }

    set(name: string, value: Object) {
        if (typeof (Storage) !== "undefined") {
            sessionStorage.setItem(name, JSON.stringify(value))
        }
    }

    public clear() {
        if (typeof (Storage) !== "undefined") {
            sessionStorage.clear()
        }
    }

    public setProjects(projects: Array<ProjectSlugAccordion>) {
        this.set("projects", projects)
        this._projects = projects;
    }

    public setSensors(sensors: Array<SensorSource>) {
        this.set("sensors", sensors)
        this._sensors = sensors;
    }
}
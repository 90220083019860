import {useNavigate, useParams} from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import { Project } from "../../components/Project/Project";
import { useEffect } from "react";
import {createApiService} from "../../utils/api";
import {config} from "../../constants";

export const Dashboard = () => {
  const location = useParams();
  const navigate = useNavigate();
  const api = createApiService(config.API_BASE_URL)

  useEffect(() => {
      const getStatus = async () => {
          await api.auth.status()
      }

      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

      getStatus()
          .catch(() => {
              console.error('Error authenticating, redirecting to login');
              navigate('')
          })
}, [location, history]);


  return (
    <Layout>
      <Project />
    </Layout>
  );
};

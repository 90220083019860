import { FunctionComponent } from "react";
import classes from "./Graphs.module.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  //Customized,
  //Rectangle,
  AreaChart,
  Area,
  Tooltip,
  ReferenceLine,
  BarChart,
  Bar,
} from "recharts";
import {format} from 'date-fns'

const data = [
  {
    name: "",
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: "Oct 21",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Oct 22",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Oct 23",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Oct 24",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Oct 25",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Oct 26",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

interface GraphDataItem { name: string; pv: number; uv: number; amt: number };

type GraphData = GraphDataItem[]
function CleanGraphData(graphData: GraphData): GraphData {
  const newGraphData = graphData.map(({name, pv, uv, amt}) => {
    // TODO: Manage this conversion at the server side in serialization
     const newData = ({
      name,
      pv: parseFloat(pv < 0.01 ? pv.toFixed(5) : pv.toFixed(3)),
      uv: parseFloat(uv < 0.01 ? uv.toFixed(5) : uv.toFixed(3)),
      amt: parseFloat(amt < 0.01 ? amt.toFixed(5) : amt.toFixed(3)),
    });
     return newData
  });
  newGraphData.sort((a, b) => a.name.localeCompare(b.name));
  return newGraphData
}

const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), 'MMM/dd HH:mm');
  } catch (e) {
    console.debug(e)
    return dateString;
  }
}

const filterDataByDate = (data: any, start: number | Date, end: number | Date) => {
  return data.filter((item: { name: string | number | Date; }) => {
    const itemDate = new Date(item.name);
    return itemDate >= start && itemDate <= end;
  })
};

const formatTooltipLabel = (timestamp: string) => {
  try {
    return format(new Date(timestamp), 'MMM dd, HH:mm');
  } catch (e) {
    console.error(e)
    return timestamp;
  }
}

function CustomTooltipGreen({ payload, label, active }: any) {
  if (active && payload.length > 0) {
    return (
      <div className={classes.customTooltip}>
        <p>{formatTooltipLabel(label)}</p>
        <h4>{payload[0].value}</h4>
      </div>
    );
  }
  return null;
}

function CustomTooltipBar({ payload, label, active }: any) {
  if (active) {
    return (
      <div className={classes.customTooltip}>
        <p>{label}</p>
        {payload[0]?.value ? <h4>{format(new Date(payload[0].value), 'MMM/dd hh:mm:ss')}</h4> : ""}
        <h4>{payload[1]?.value}</h4>
      </div>
    );
  }
  return null;
}

/*const CustomizedRectangle: FunctionComponent<any> = ({
  formattedGraphicalItems,
}) => {
  // get first and second series in chart
  const firstSeries: any = formattedGraphicalItems[0];
  const secondSeries: any = formattedGraphicalItems[1];

  console.log("First Series ", firstSeries);
  // render custom content using points from the graph
  return firstSeries?.props?.points?.map((firstSeriesPoint: any, index: any) => {
    const secondSeriesPoint = secondSeries?.props?.points[index];
    const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;

    return (
      <Rectangle
        key={firstSeriesPoint.payload.name}
        width={10}
        height={yDifference}
        x={secondSeriesPoint.x - 5}
        y={secondSeriesPoint.y}
        fill={"#F09"}
        radius={1000}
      />
    );
  });
};*/

export const PinkBarChart: FunctionComponent<{
  graphData?: Array<{ name: string; pv: number; uv: number; amt: number }>,
  start: Date,
  end: Date,
}> = ({ graphData = data, start, end  }) => {
  const filteredData = filterDataByDate(graphData, start, end);
  return (
    <div className={classes["sensor-load-container"]}>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={300}
          data={CleanGraphData(filteredData)}
          margin={{
            top: 10,
            right: 30,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type={"category"}
            domain={['dataMin', 'dataMax']}
            dataKey="name"
            fontFamily="SpaceGrotesk"
            dy={10}
            angle={-45}
            textAnchor={"end"}
            fontSize={10}
            tickFormatter={formatDate}
          />
          <YAxis fontFamily="SpaceGrotesk" dx={-5}/>
          <Bar dataKey={"pv"} fill={"#F09"} />
          {/*<Line type="monotone" dataKey="pv" stroke="transparent" />*/}
          {/*<Line type="monotone" dataKey="uv" stroke="transparent" />*/}
          {/*<Customized component={CustomizedRectangle} />*/}
          <Tooltip content={<CustomTooltipBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const GreenAreaChart: FunctionComponent<{
  graphData?: Array<{ name: string; pv: number; uv: number; amt: number }>,
  start: Date,
  end: Date,
}> = ({ graphData = data, start, end }) => {
  const filteredData = filterDataByDate(graphData, start, end);
  return (
    <div className={classes["sensor-load-container"]}>
      <div className={classes["heading"]}>
        <h3>Load</h3>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart data={filteredData} width={500} height={300} margin={{ right: 30}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontFamily="SpaceGrotesk" dy={10}/>
          <YAxis fontFamily="SpaceGrotesk" dx={-5}/>
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#32FFC2"
            fill="#32FFC21A"
            strokeWidth={2}
          />
          <Tooltip content={<CustomTooltipGreen />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export const PinkLineChart: FunctionComponent<{
  graphData?: Array<{ name: string; pv: number; uv: number; amt: number }>;
  start: Date,
  end: Date,
  height?: number;
}> = ({ graphData = data, start, end, height = 500}) => {

  const filteredData = filterDataByDate(graphData, start, end);

  return (
    <div className={classes["sensor-load-container"]}>
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          width={500}
          height={300}
          data={CleanGraphData(filteredData)}
          margin={{
            top: 10,
            right: 30,
            bottom: 40,
            left: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type={"category"}
            domain={['dataMin', 'dataMax']}
            dataKey="name"
            fontFamily="SpaceGrotesk"
            //label={yUnit}
            dy={10}
            angle={-45}
            textAnchor={"end"}
            fontSize={10}
            tickFormatter={formatDate}
          />
          <YAxis fontFamily="SpaceGrotesk" dx={-5}/>
          <ReferenceLine
            y={7000}
            label={{
              position: "left",
              value: "Baseline",
              fill: "#0DCE95",
              fontFamily: "SpaceGrotesk",
              dy: 15,
              dx: 90,
              fontSize: 12,
            }}
            stroke="#0DCE95"
            strokeWidth={2}
            strokeDasharray="10"
          />
          <Line type="monotone" dataKey="pv" stroke="#FF00A8" strokeWidth={2} activeDot={{ r: 6 }} />
          <Tooltip content={<CustomTooltipGreen />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const GreenAreaAnalyticsChart: FunctionComponent<{
  graphData?: Array<{ name: string; pv: number; uv: number; amt: number }>;
  start: Date,
  end: Date,
  height?: number;
}> = ({ graphData = data, start, end, height = 500 }) => {
  const filteredData = filterDataByDate(graphData, start, end);

  return (
    <div className={classes.chartContainer}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={CleanGraphData(filteredData)}
          width={500}
          height={300}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 40
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type={"category"}
            domain={['dataMin', 'dataMax']}
            dataKey="name"
            fontFamily="SpaceGrotesk"
            dy={10}
            angle={-45}
            textAnchor={"end"}
            fontSize={10}
            tickFormatter={formatDate}
          />
          <YAxis fontFamily="SpaceGrotesk" dx={-5}/>
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#32FFC2"
            fill="#32FFC21A"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
          <Tooltip content={<CustomTooltipGreen />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

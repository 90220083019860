//import { projectImage } from "../../assets";
import classes from "./AllProjects.module.scss";
import { ProjectDetails } from "../../components/Project";
import { Layout } from "../../components/Layout/Layout";
import {FunctionComponent, useEffect, useState} from "react";
import {createApiService} from "../../utils/api";
import {config} from "../../constants.ts";
import {OverviewPropsType} from "../../utils/types/ProjectDetails.ts";
import {Button} from "../../components/Buttons/Button";
import { ProjectDetailSkeleton } from "../../components/Project/ProjectDetails.tsx";
import { Loading } from "../../components/Loading/Loading.tsx";


export const AllProjects: FunctionComponent = () => {
    const [projectsInfo, setProjectsInfo] = useState<OverviewPropsType>();
    const api = createApiService(config.API_BASE_URL);

    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      await api.context.getProjects()
        .then((response) => {
          setProjectsInfo(response)
          return response
        })
    };

    fetchProjects().catch(() => { }).finally(() => setLoading(false));
  }, [])

    return (
      loading ? <Loading/> : 
    <Layout>
      <h1 className={classes.title}>All Projects</h1>
      <div className={classes.content}>
          <div className={classes.innerContent}>
            { !projectsInfo && <ProjectDetailSkeleton />}
            { projectsInfo?.sites && projectsInfo?.sites?.length === 0 && !config.DEMO_MODE &&
              <article className={classes.projectDetails}>
                <Button isLink href={'/add-stream'} >
                  Add New Project
                </Button>
              </article>
            }
            { projectsInfo?.sites &&
                projectsInfo?.sites?.map((i, index) => (
                  <ProjectDetails key={index} {...i} />
                ))
            }
          
        </div>
      </div>
    </Layout>
  );
};

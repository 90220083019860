import {FunctionComponent, useEffect, useState} from "react";
import { Layout } from "../../components/Layout/Layout";
import { TableWithoutSorting } from "../../components/Table/Table";
import { Cell } from "@table-library/react-table-library/table";
import classes from "./Notifications.module.scss";
import {DemiaNotification} from "../../utils/types/ProjectDetails";
import {createApiService} from "../../utils/api";
import {config} from "../../constants";
import {format} from 'date-fns'



const fields = ["Id", "Message", "Timestamp"];

const NotificationsRowTemplate: FunctionComponent<{ item: DemiaNotification }> = ({
  item,
}) => {
  return (
    <>
      <Cell>
        <div className={classes["name-cell"]}>
          {/*!item.isRead ? <div className={classes["bullet"]}></div> : ""*/}
          <span className={classes["text"]}>{item.user}</span>
        </div>
      </Cell>
      <Cell>
        <span className={classes["text"]}>{item.message}</span>
      </Cell>
      <Cell>
        <span className={classes["text"]}>{item.timestamp.toDateString()}, {format(item.timestamp, 'hh:mm:ss b')}</span>
      </Cell>
      {/*<Cell>
        <div className={classes["actions"]}>
          <ReactSVG src={messageIcon}></ReactSVG>
          <ReactSVG src={actionsIcon}></ReactSVG>
        </div>
      </Cell>*/}
    </>
  );
};

export const Notifications = () => {
  const api = createApiService(config.API_BASE_URL)

  const [nodes, setNodes] = useState<DemiaNotification[]>([]);

  useEffect( () => {
    const getNotifications = async () => {
      return await api.context.getNotifications()
        .then(response => {
          const nodes = response.notifications.map((item) => {
             return {
               isRead: false,
               user: item.user,
               timestamp: new Date(item.timestamp),
               message: item.message
             }
          });
          nodes.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
          setNodes(nodes)
        })
    }

    getNotifications()
      .catch(err => {
        console.error(err);
      })
  }, [])

  return (
    <Layout>
      <h1 className={classes.title}>Notifications</h1>
      <TableWithoutSorting
        data={{ nodes }}
        fields={fields}
        RowTemplate={NotificationsRowTemplate}
        limit={10}
      />
    </Layout>
  );
};

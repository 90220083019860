import { FunctionComponent, ReactElement, ReactPortal } from "react";
import { Footer } from "../Footer/Footer";
import classes from './Loading.module.scss';
import { PropagateLoader } from "react-spinners";

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;

interface ReactNodeArray extends Array<ReactNode> {}
type ReactFragment = {} | ReactNodeArray;
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

export const Loading: FunctionComponent = () => {
    return (
        <main className={classes['main']}>
            <div className={classes['main__content']}>
                <div className={classes['main__content-children']}>
                    <PropagateLoader color="#36d7b7" loading={true} />
                </div>
                <Footer />
                <div className={classes['gradient1']}></div>
                <div className={classes['gradient2']}></div>
            </div>
        </main>
    )
}
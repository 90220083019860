import { FunctionComponent, useEffect, useState } from 'react';
import classes from './SidebarAccordion.module.scss';
import { NavLink, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { allProjectsIcon, arrowIcon, projectIcon } from '../../../assets';
import { createApiService } from '../../../utils/api';
import { config } from '../../../constants';

export const SidebarAcccordion: FunctionComponent = () => {
  const api = createApiService(config.API_BASE_URL);

  const [isLoading, setLoading] = useState<boolean>(api.cache.projects.length == 0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeProjectName, setActiveProjectName] = useState<string>('');
  const [activeProjectId, setActiveProjectId] = useState<number>();

  const { slug } = useParams();
  const [ projects, setProjects ] = useState(api.cache.projects);

  useEffect(() => {
    // Fetch project but dont redirect if it fails
    api.context.getProjects(false).then((projects) => {
      let mapped = projects.sites?.map((site) => {
        return {
          projectName: site.projectName,
          slug: `${site.projectId}`,
          projectId: parseInt(site.projectId)
        }
      }) || [];
      setLoading(false);
      setProjects(mapped);
    }).catch((_e) => { });
  }, []);

  useEffect(() => {
    api.cache.setProjects(projects);
  }, [projects])

  useEffect(() => {
    const activeProject = projects.find((project) => project.slug === slug);

    if (activeProject) {
      setActiveProjectName(activeProject.projectName);
      setActiveProjectId(activeProject.projectId);
    }
  }, []);

  const toggleAccordion = () => setIsOpen((prev) => !prev);
  const closeAccordion = () => setIsOpen(false);

  return (
    <div className={classes['sidebar-accordion']}>
      <div
        className={classes['sidebar-accordion-header']}
        onClick={toggleAccordion}
      >
        <div className={classes['sidebar-accordion-header__info']}>
          <span>PROJECTS</span>
          <span className={classes['project-name']}>{activeProjectName}</span>
        </div>
        <img
          className={`${isOpen && classes['rotate']}`}
          src={arrowIcon}
          alt=""
        />
      </div>
      <div
        className={
          classes['sidebar-accordion-body'] +
          ' ' +
          (isOpen && classes['active'])
        }
      >
        <div className={classes['links']}>
          {isLoading ? <>
            <div className={classes['skeleton']}>
              <ReactSVG wrapper="span" src={projectIcon} className={classes.projectIcon} />
              Loading..
            </div>
          </> :
          projects.map((project, index) => (
            <NavLink
              key={index}
              to={`/projects/${project.slug}`}
              className={() => {

                if (project.projectId === activeProjectId) {
                  return `${classes['active']}`
                }
              }}
              onClick={() => {
                closeAccordion();
                setActiveProjectName(project.projectName);
                setActiveProjectId(project.projectId);
              }}
            >
              <ReactSVG wrapper="span" src={projectIcon} className={classes.projectIcon}/>
              {project.projectName}
            </NavLink>
          ))}
          <div className={classes['spacer']}></div>
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              isActive
                ? `${classes['all-projects']} ${classes['active']}`
                : classes['all-projects']
            }
          >
            <ReactSVG
              wrapper="span"
              src={allProjectsIcon}
              className={classes.hamburger}
            ></ReactSVG>
            All Projects
          </NavLink>
        </div>
      </div>
    </div>
  );
};

import {useEffect, useState} from "react";
import classes from "./Profile.module.scss";
import {createApiService} from "../../utils/api";
import {config} from "../../constants.ts";
import { IdentityMethod } from "../../components/Identity";
import { UserProfileData } from "../../utils/types";
import { TableWithoutSorting } from "../../components/Table/Table.tsx";
import { Layout } from "../../components/Layout/Layout.tsx";
import {loadStripe, } from "@stripe/stripe-js";

import {Elements} from "@stripe/react-stripe-js";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {useAuth0} from "@auth0/auth0-react";
import {CheckoutForm} from "../../components/Profile/Stripe.tsx";
import {SubscriptionInfo} from "../../components/Profile/SubscriberInfo.tsx";
import {PropagateLoader} from "react-spinners";
import {OverviewPropsType} from "../../utils/types/ProjectDetails.ts";
import {SubscriptionInfoProps} from "../../utils/types/SubscriptionInfo.ts";


export const Profile = () => {
  const api = createApiService(config.API_BASE_URL);
  const {user, isAuthenticated, getIdTokenClaims, isLoading } = useAuth0();
  const stripePromise = loadStripe('pk_test_51PafG7Ano02HsegSRDF8wLqHTtx6ETaIYmUzCoX5YOSuUTzr1G2V59pQIk0yESKrJs73J72T1nvrTOSEXn0cVc6P00Wgt6Yrzl');

  const [profile , setProfile] = useState<UserProfileData>();
  const [subscription, setSubscription] = useState<{ [x: string]: SubscriptionInfoProps } | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [sites, setSites] = useState<OverviewPropsType | null>(null);
  const fields = ["Fragment", "Type", "Controller"];
    
  useEffect(() => {
      const fetchSites = async (): Promise<OverviewPropsType> => {
          return api.context.getProjects()
              .then((response) => {
                  setSites(response);
                  return response;
              })
              .catch((err) => {
                  console.error(err.message);
                  throw err;
              });
      }

      const fetchSubscriptions = async (customerId: string, sites: OverviewPropsType) => {
          api.user.getSubscription(customerId)
              .then((response) => {
                  response.map((sub) => {
                      sites.sites.map((site) => {
                          const projectId = site.projectId;
                          if (sub.metadata['project_id'] === projectId) {
                              const newSubscription = subscription ? {...subscription} : {};
                              newSubscription[projectId] = sub;
                              setSubscription(newSubscription);
                          }
                      })
                  })
              })
      }

      if (!isLoading) {
        api.user.profile()
            .then(setProfile)
            .then(() => {
                fetchSites()
                    .then((sites) => {
                          if (isAuthenticated && user) {
                              getIdTokenClaims()
                                  .then((token) => {
                                      if (token) {
                                          setCustomerId(token.stripeCustomerId);
                                          if (!config.DEMO_MODE) {
                                              fetchSubscriptions(token.stripeCustomerId, sites)
                                                  .catch((err) => {
                                                      throw err;
                                                  })
                                          }
                                      }
                                  });
                          }
                      })
              })
              .catch((err) => {
                  console.error(err.message);
              })
    }
  }, [isLoading]);

  return (
      <Layout>
        <div className={classes.profile}>
          <h1 className={classes.title}>Profile</h1>
          <div className={classes.content}>
            <section className={classes.projectInfo}>
              <div className={classes.details}>
                <div className={classes.item}>
                  <h6>Username: </h6>
                  <p onClick={() => navigator.clipboard.writeText(profile!.user!.username)}>{profile?.user?.username || <Skeleton/>}</p>
                </div>
                <div className={classes.item}>
                  <h6>User ID</h6>
                  <p onClick={() => navigator.clipboard.writeText(profile!.user!.keycloak_id)}>{profile?.user?.keycloak_id || <Skeleton/>}</p>
                </div>
                <div className={classes.item}>
                  <h6>DID Document ID</h6>
                  <p onClick={() => navigator.clipboard.writeText(profile!.doc_id)}>{profile?.doc_id || <Skeleton/>}</p>
                </div>
              </div>
            </section>
            {!config.DEMO_MODE && 
            <section className={classes.subscription}>
                <h2 className={classes.title}>Subscription Details</h2>
                {sites && sites.sites.map((site) => {
                    const element = customerId && subscription ?
                        Object.keys(subscription).length !== 0 && subscription[site.projectId] ?
                            <SubscriptionInfo customerId={customerId} site={site}
                                subscription={subscription[site.projectId]} /> :
                            <Elements stripe={stripePromise}>
                                <CheckoutForm customerId={customerId} site={site} />
                            </Elements> :
                        <PropagateLoader color="#36d7b7" loading={true} />;
                    const containerClass = customerId ? classes.projectInfo : classes.loadingBar;
                    return (
                        <div className={containerClass}>
                            {element}
                        </div>
                    )
                })}
                {!sites && <h4>No Sites Available</h4>}
            </section>
            }
            <section className={classes.methods}>
              <TableWithoutSorting
                data={{ nodes: profile?.methods || [] }}
                fields={fields}
                RowTemplate={IdentityMethod}
                limit={10}/>
            </section>
          </div>
        </div>
      </Layout>
  );
};

// AuthService.ts
import { BaseApiService } from './BaseApiService';
import {SubscriptionInfoProps} from "../types/SubscriptionInfo.ts";

export class UserService extends BaseApiService {
    constructor(baseURL: string) {
        super(baseURL);
    }

    async getIdentity(redirect: boolean = true): Promise<any> {
        return this.get('api/user/identity', undefined, redirect)
    }

    async createIdentity() {
        return this.post('api/user/identity/create', {})
    }

    async deleteIdentity() {
        return this.post('api/user/identity/delete', {})
    }

    async profile() {
        return this.getIdentity();
    }

    async getSubscription(customerId: string): Promise<SubscriptionInfoProps[]> {
        return this.post('api/user/subscriptions', {customerId});
    }

    async cancelSubscription(subscriptionId: string) {
        return this.post('api/user/subscriptions/cancel', {subscriptionId});
    }
}

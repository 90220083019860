import { Layout } from "../../components/Layout/Layout";
import classes from "./Documentation.module.scss";
import { plusIcon, exportIcon, deleteTrash } from "../../assets";
import { useEffect, useState } from "react";
import { Button } from "../../components/Buttons/Button";
import { TableWithoutSorting } from "../../components/Table/Table";
import { Cell } from "@table-library/react-table-library/table";
import { DocumentationModal } from "../../components/Modals";
import { createApiService } from "../../utils/api";
import { sizeToReadable, timeToReadable } from "../../utils/display";
import { config } from "../../constants";
import { useParams } from "react-router-dom";
import { FileType } from "../../utils/types";

const fields = ["Name", "Type", "Uploaded On", "Size"];

const DocumentationRowTemplate: React.FC<{
  item: {
    file: FileType,
    onDelete: (iten: FileType) => void,
  }
}> = (item) => {
  
  const { slug } = useParams();

  const [isOpen, setIsOpen] = useState <Boolean>(false);
  const toggleAccordion = () => setIsOpen((prev) => !prev)

  const api = createApiService(config.API_BASE_URL)

  async function onDelete() {
    toggleAccordion();
    await item.item.onDelete(item.item.file);
  }

  async function download(file: FileType) {
    try {
      let res = await api.context.downloadFile(slug!, file.name);
      const link = document.createElement("a");
      link.download = `${file.name}`;
      const blob = new Blob([res], { type: file.metadata?.type || "text/plain" });
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Cell onClick={toggleAccordion} className={ isOpen ? classes.clickedTop : ""}>{item.item.file.name}</Cell>
      <Cell onClick={toggleAccordion} className={ isOpen ? classes.clickedTop : ""}>{item.item.file.metadata?.type || ""}</Cell>
      <Cell onClick={toggleAccordion} className={ isOpen ? classes.clickedTop : ""}>{timeToReadable(item.item.file.lastModified)}</Cell>
      <Cell onClick={toggleAccordion} className={ isOpen ? classes.clickedTop : ""}>{sizeToReadable(item.item.file.metadata?.size || "0")}</Cell>
      {isOpen && <Cell className={classes.clickedBottom}>
        <Button
              icon={exportIcon}
              className={classes.button}
              onClick={async () => await download(item.item.file)}
            >
          Download
        </Button>
      </Cell>}
      {isOpen && <Cell className={classes.clickedBottom}>
        <Button
              icon={deleteTrash}
              className={classes.button}
              onClick={onDelete}
            >
          Delete
        </Button>
      </Cell>}
      {isOpen && <Cell onClick={toggleAccordion} className={classes.clickedBottom} />}
      {isOpen && <Cell onClick={toggleAccordion} className={classes.clickedBottom} />}
    </>
  );
};

const reportsCertificationFields = [
  "Name",
  "Entity",
  "Type",
  "Uploaded On",
  "Size",
];

const reportsCertificationRowTemplate: React.FC<{ item: any }> = () => {
  return (
    <>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
    </>
  );
};

export const Documentation = () => {
  
  const api = createApiService(config.API_BASE_URL);

  const { slug } = useParams();
  const [projectFiles, setProjectFiles] = useState<FileType[]>([]);
  const [monitoringFiles] = useState<FileType[]>([]);
  const [reportsFiles] = useState<FileType[]>([]);
  const [certificationFiles] = useState<FileType[]>([]);
  
  async function deleteFile(file: FileType) {
    try {
      //let _res = await api.context.deleteFile(slug!, file.name);
      let newFiles = [...projectFiles];
      var index = newFiles.indexOf(file);
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      setProjectFiles(newFiles)
    } catch (err) {
      console.error(err);
    }
  }
  
  const updateFiles = (files: FileType[]) => {
    let mappedFiles = files.map((s) => {
      return {
        ...s,
        lastModified: new Date(s.lastModified),
      }
    })
    setProjectFiles(mappedFiles);
  }

  const [updateMetadata, setUpdateMetadata] = useState(false);
  useEffect(() => {
    if (projectFiles.length > 0) {
      api.context.filesMetadata(slug!, projectFiles).then((res) => {
        updateFiles(res.files.map((f) => ({
          ...f,
          metadata: {
            ...f.metadata!,
            type:  "" + f.name.split('.').pop()
          }
        })));
      });
    }
  }, [updateMetadata]);

  useEffect(() => {
    api.context.listFiles(slug!).then((res) => {
      updateFiles(res.files);
      setUpdateMetadata(true);
    });
  }, []);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (newFiles: FileType[]) => {
    setIsModalOpen(false);
    if (newFiles.length > 0) {
      setProjectFiles(newFiles.concat(projectFiles));
    }
  };

  return (
    <>
      <Layout>
        <div className={classes.documentation}>
          <div className={classes.titleContainer}>
            <h1 className={classes.title}>Documentation</h1>
            <Button
              icon={plusIcon}
              className={classes.button}
              onClick={openModal}
            >
              Add New
            </Button>
          </div>
          <div className={classes.tabs}>
            <div
              className={
                activeTab === 1
                  ? `${classes.tab} ${classes.active}`
                  : `${classes.tab}`
              }
              onClick={() => handleTabClick(1)}
            >
              Project Documents
            </div>
            <div
              className={
                activeTab === 2
                  ? `${classes.tab} ${classes.active}`
                  : `${classes.tab}`
              }
              onClick={() => handleTabClick(2)}
            >
              Monitoring Plans
            </div>
            <div
              className={
                activeTab === 3
                  ? `${classes.tab} ${classes.active}`
                  : `${classes.tab}`
              }
              onClick={() => handleTabClick(3)}
            >
              Reports
            </div>
            <div
              className={
                activeTab === 4
                  ? `${classes.tab} ${classes.active}`
                  : `${classes.tab}`
              }
              onClick={() => handleTabClick(4)}
            >
              Certification
            </div>
          </div>
          {activeTab === 1 && (
            <div className={classes.documentationTable}>
              <TableWithoutSorting
                className={classes.table}
                data={{ nodes: projectFiles.map((x) => ({ onDelete: deleteFile, file: x})) }}
                fields={fields}
                RowTemplate={DocumentationRowTemplate}
                limit={ 10 }
              />
            </div>
          )}
          {activeTab === 2 && (
            <div className={classes.documentationTable}>
              <TableWithoutSorting
                className={classes.table}
                data={{ nodes: monitoringFiles.map((x) => ({ onDelete: deleteFile, file: x})) }}
                fields={fields}
                RowTemplate={DocumentationRowTemplate}
                limit={ 10 }
              />
            </div>
          )}
          {activeTab === 3 && (
            <div className={classes.documentationTable}>
              <TableWithoutSorting
                className={classes.table}
                data={{ nodes: reportsFiles.map((x) => ({ onDelete: deleteFile, file: x})) }}
                fields={reportsCertificationFields}
                RowTemplate={reportsCertificationRowTemplate}
                limit={ 10 }
              />
            </div>
          )}
          {activeTab === 4 && (
            <div className={classes.documentationTable}>
              <TableWithoutSorting
                className={classes.table}
                data={{ nodes: certificationFiles.map((x) => ({ onDelete: deleteFile, file: x}))  }}
                fields={reportsCertificationFields}
                RowTemplate={reportsCertificationRowTemplate}
                limit={ 10 }
              />
            </div>
          )}
        </div>
      </Layout>
      {isModalOpen && <DocumentationModal onClose={closeModal} />}
    </>
  );
};

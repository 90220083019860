//import React from 'react'
import ReactDOM from 'react-dom/client'
import App, {Auth0ProviderWithRedirectCallback} from './App.tsx'
import './index.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
  //<React.StrictMode>
    <Auth0ProviderWithRedirectCallback>
        <App />
    </Auth0ProviderWithRedirectCallback>
  //</React.StrictMode>

)

//import { copiulemu } from "../../assets";
import {ProjectInfoPropsType} from "../../utils/types";
import classes from "./ProjectInfo.module.scss";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {GoogleMaps} from "./GoogleMaps";

export const ProjectInfo: React.FC<ProjectInfoPropsType> = ({projectInfo, location}) => {
  const {type, methodology, projectDev, projectId} = projectInfo;
  return (
    <section className={classes.projectInfo}>
      <div style={{height: "100%", width: "46%"}}>
        <GoogleMaps {...location}/>
      </div>
      <div className={classes.details}>
        <div className={classes.item}>
          <h6>Project Type</h6>
          <p>{type || <Skeleton/>}</p>
        </div>
        <div className={classes.item}>
          <h6>Methodology</h6>
          <p>{methodology || <Skeleton/>}</p>
        </div>
        <div className={classes.item}>
          <h6>Project Developer</h6>
          <p>{projectDev || <Skeleton/>}</p>
        </div>
        <div className={classes.item}>
          <h6>Project ID</h6>
          <p>{projectId || <Skeleton/>}</p>
        </div>
      </div>
    </section>
  );
}
import { useParams } from "react-router-dom";
import { CargoSensor, Feedstock, Flowmeter } from "..";
import { config } from "../../constants";
import { createApiService } from "../../utils/api";
import { useEffect, useState } from "react";
import { SensorDashboardContext } from "../../utils/types/ProjectDetails";

const CARGO: "cargo" = "cargo";
const FLOW: "flow" = "flow";
const FEED: "feed" = "feed";
export type SensorTypes = typeof CARGO | typeof FLOW | typeof FEED;

function sensorToTemplate(sensor: string): SensorTypes {
  sensor = sensor.toLowerCase();
  if (sensor.includes("cargo") || sensor.includes("grape")) {
    return CARGO
  } else if (sensor.includes("flow")) {
    return FLOW
  } else if (sensor.includes("feed")) {
    return FEED
  } else {
    return CARGO
  }
}

export const SensorTemplates = () => {
  const { slug, sensorsSlug } = useParams();
  const api = createApiService(config.API_BASE_URL);
  const [sensor, setSensor] = useState<SensorDashboardContext | undefined>();
  
  useEffect(() => {
    api.context.getSensor(slug!, sensorsSlug!).then((context) => {
      setSensor(context);
    });
  }, [sensorsSlug]);

  const type = sensorToTemplate(sensorsSlug!);
  switch (type) {
    case CARGO: return <CargoSensor sensor={sensor} />
    case FLOW: return <Flowmeter sensor={sensor} />
    case FEED: return <Feedstock sensor={sensor} />
  }
};

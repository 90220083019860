import { ActivityPropsType } from "../../utils/types";
import classes from "./ActiveUser.module.scss";
import {FunctionComponent} from "react";
import {DemiaNotification} from "../../utils/types/ProjectDetails";
import {format} from "date-fns";

export const ActiveUser: React.FC<ActivityPropsType> = ({ activity }) => {
  return (
    <div className={classes.activeUser}>
      <div className={classes.userLeft}>
        <div className={classes.userIcon}>{activity.initials}</div>
        <div className={classes.userInfo}>
          <h4>
            {activity.name}
          </h4>
          <p>{activity.action}</p>
        </div>
      </div>
      <span>{activity.timestamp}</span>
    </div>
  );
};


export const NotificationBlob: FunctionComponent<DemiaNotification> = (item) => {
  return (
    <div className={classes.activeUser}>
      <div className={classes.notificationLeft}>
        <div className={classes.userInfo}>
          <p>
            User: {item.user}
          </p>
          <h4>Activity: {item.message}</h4>
        </div>
      </div>
      <span className={classes.timestamp}>{format(item.timestamp, 'MMM/dd hh:mm:ss')}</span>
    </div>
  )
}


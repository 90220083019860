import { Link, useLocation, useParams } from "react-router-dom";
import {
  arrowLeft,
  cargoAverage,
  cargoCondidence,
  cargoCurrent,
  exportIconBlack,
  flowmeter,
  hamburgerIcon,
  statusConnected,
  statusDisconnected,
} from "../../assets";
import { Layout } from "../../components/Layout/Layout";
import classes from "./Flowmeter.module.scss";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import {PinkLineChart} from "../../components/Graphs/Graphs";
import { TableSorting } from "../../components/Table/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Analytics/CalendarStyle.scss";
import { SensorDashboardContext } from "../../utils/types/ProjectDetails";
import { timeToReadable } from "../../utils/display";
import { ReadingData } from "../../utils/types";
import Skeleton from "react-loading-skeleton";
import {ReadingRowTemplate, sortFns, ReadingFields, readingContextToData} from "../../components/Table/ReadingsTable";

const flowDataEmpty = {
  image: flowmeter,
  active: true,
  infoData: [
    {
      title: "Sensor ID",
      value: "",
    },
    {
      title: "Accuracy",
      value: "",
    },
    {
      title: "Manufacturer",
      value: "",
    },
    {
      title: "Installed on",
      value: "",
    },
    {
      title: "Model",
      value: "",
    },
    {
      title: "Last Calibration",
      value: "",
    },
    {
      title: "Serial Number",
      value: "",
    },
    {
      title: "Next Calibration",
      value: "",
    },
  ],
};

const flowInfo = {
  source: "Flowmeter 1",
  lastUpdate: "27 Oct 2023 - 6:45 PM",
  unit: "0.05 Nm3",
};

const flowmeterDataEmpty = [
  {
    img: cargoCurrent,
    text: "Current load",
    value: 0,
    valueUnit: "",
  },
  {
    img: cargoAverage,
    text: "Daily average load",
    value: 0,
    valueUnit: "",
  },
  {
    img: cargoCondidence,
    text: "Data confidence",
    value: 0,
    valueUnit: "%",
  },
];

export const Flowmeter: React.FC<{ sensor: SensorDashboardContext | undefined; }> = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex: number) => {
    if (tabIndex == 1) {
      setIsVisible(false);
    }
    setActiveTab(tabIndex);
  };

  const [isVisible, setIsVisible] = useState(false);

  const exportButtonHandler = () => {
    setIsVisible((prev) => !prev);
  };

  const { slug } = useParams();
  const url = `/projects/${slug}/data-sources`;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [sensor, setSensor] = useState<SensorDashboardContext>();
  const [flowmeterData, setFlowmeterData] = useState<Array<{
    img: string,
    text: string,
    value: number,
    valueUnit: string,
  }>>(flowmeterDataEmpty.map((a) => { return { ...a } }));

  const [flowData, setFlowData] = useState(flowDataEmpty.infoData.map((a) => {
    return { ...a }
  }));

  const [tableList, setTableList] = useState <Array<ReadingData>>([]);
  const [graphData, setGraphData] = useState <Array<{
    name: string,
    uv: number,
    pv: number,
    amt: number,
  }>>([]);
  useEffect(() => {
    if (sensor) {
      setGraphData(Array.from(Object.values(sensor.sensor.readings)).map((v) => {
        return {
          name: v.timestamp,
          uv: v.value,
          pv: v.value,
          amt: v.value,
        }
      }));

      const readings = Array.from(Object.values(sensor.sensor.readings)).map((v) => {
        return readingContextToData(v, sensor.sensor.equipment.units)
      });

      readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      setTableList(readings);
    }
  }, [sensor]);

  useEffect(() => {
    if (props.sensor) {
      setSensor(props.sensor);
      let numbers = [...flowmeterData];
      numbers[0].value = Math.round(props.sensor.stateData.realTimeFlow*100000)/100000;
      // TEMP
      numbers[0].valueUnit = "m3/hour"/*props.sensor.sensor.equipment.units*/;
      numbers[1].value = Math.round(props.sensor.stateData.currentDayAvg*100000)/100000;
      // TEMP
      numbers[1].valueUnit = "m3/hour"/*props.sensor.sensor.equipment.units*/;
      numbers[2].value = Math.round(props.sensor.sensor.avgcf*10000)/10000;
      setFlowmeterData(numbers);

      let newData = [...flowData];
      newData[0].value = props.sensor.sensor.equipment.id;
      newData[1].value = props.sensor.sensor.equipment.accuracy + "";
      newData[2].value = props.sensor.sensor.equipment.manufacturer;
      newData[3].value = props.sensor.sensor.equipment.installed + "";
      newData[4].value = props.sensor.sensor.equipment.eqType;
      // Last calibration
      newData[5].value = "Never";
      newData[6].value = props.sensor.sensor.equipment.serialNo;
      // Next calibration
      newData[7].value = "Soon";
      setFlowData(newData);
    }
  }, [props.sensor]);

  return (
    <Layout>
      <div className={classes.flowmeter}>
        <div className={classes.titleContainer}>
          <Link to={url} className={classes.backLink}>
            <ReactSVG src={arrowLeft} className={classes.arrowIcon}></ReactSVG>
            { sensor ? sensor.sensor.equipment.name : "Flometer" }
          </Link>
          <div className={classes.datePickerContainer}>
            <div className={classes.datepicker}>
              <DatePicker
                calendarClassName="calendarStyle"
                popperClassName="pooperStyle"
                selected={startDate}
                onChange={(date) => date && setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="MMMM d, yyyy"
              />
              <span>-</span>
              <DatePicker
                calendarClassName="calendarStyle"
                popperClassName="pooperStyle"
                selected={endDate}
                onChange={(date) => date && setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="MMMM d, yyyy"
              />
            </div>
            <span className={classes.divider}></span>
          {activeTab === 2 && (
              <div
                className={classes.moreOptions}
                onClick={exportButtonHandler}
              >
                <ReactSVG
                  src={hamburgerIcon}
                  className={classes.hamburgerIcon}
                ></ReactSVG>
              </div>
          )}
          </div>
          {isVisible && (
            <div className={classes.export}>
              <div className={classes.exportWrapper}>
                <h4>Export</h4>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.exportIcon}
                  ></ReactSVG>
                  Export to CSV
                </button>
                <button type="button">
                  <ReactSVG
                    src={exportIconBlack}
                    className={classes.exportIcon}
                  ></ReactSVG>
                  Export to XLSV
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={classes.tabs}>
          <div
            className={
              activeTab === 1
                ? `${classes.tab} ${classes.active}`
                : `${classes.tab}`
            }
            onClick={() => handleTabClick(1)}
          >
            Overview
          </div>
          <div
            className={
              activeTab === 2
                ? `${classes.tab} ${classes.active}`
                : `${classes.tab}`
            }
            onClick={() => handleTabClick(2)}
          >
            Log
          </div>
        </div>
        {activeTab === 1 && (
          <article className={classes.flowmeterWrapper}>
            <div className={classes.flowmeterInfo}>
              <figure className={classes.image}>
                <img src={flowDataEmpty.image} alt="Flow Image" />
              </figure>
              <div className={classes.rightSide}>
                <img
                  src={flowDataEmpty.active ? statusConnected : statusDisconnected}
                  className={classes.status}
                  alt="Status"
                />
                <div className={classes.data}>
                  {flowData.map((data, index) => (
                    <div className={classes.item} key={index}>
                      <h6>{data.title}</h6>
                      <p>{data.value || <Skeleton/>}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.rightMiddle}>
                {flowmeterData.map((data, index) => (
                  <div className={classes.singleItem} key={index}>
                    <img src={data.img} alt="Image" />
                    <p>{data.text}</p>
                    <h5>
                      {data.value || <Skeleton/> } <span>{data.valueUnit}</span>
                    </h5>
                  </div>
                ))}
              </div>
              <div className={classes.bottom}>
                <h4>Biogas Flow</h4>
                <div className={classes.info}>
                  <p>
                    Source <span>{flowInfo.source}</span>
                  </p>
                  <p>
                    Last update <span>{ sensor ? timeToReadable(new Date(sensor.sensor.lastUpdated)) : "never"}</span>
                  </p>
                  <p>
                    Unit <span>{flowInfo.unit}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={`${classes.chart}`}>
              <PinkLineChart graphData={graphData}  start={startDate} end={endDate}/>
            </div>
          </article>
        )}
        {activeTab === 2 && (
          <div className={classes.log}>
            <TableSorting
              className={classes.table}
              title={(sensor?.sensor.total || 0) + " Records"}
              data={{ nodes: tableList }}
              fields={ReadingFields}
              RowTemplate={ReadingRowTemplate}
              sortFns={sortFns}
              limit={7}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

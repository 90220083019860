import {useState} from "react";
import {Cell} from "@table-library/react-table-library/table";
import classes from "./ReadingsTable.module.scss";
import {config} from "../../constants";
import {TableSorting} from "./Table";
import {Annotation, ReadingDashboardContext} from "../../utils/types/ProjectDetails";
import {ReadingData} from "../../utils/types";
import {timeToReadable} from "../../utils/display";

export const readingContextToData = (r: ReadingDashboardContext, unit: string) => {
  return {
      timestamp: new Date(r.timestamp),
      value: r.value,
      address: r.address,
      annotations: Array.from(Object.values(r.annotations)),
      score: parseInt((r.score * 100).toFixed()),
      unit,
  }
}

export const ReadingFields = [
  {
    name: "Timestamp",
    sortKey: "TIMESTAMP",
  },
  {
    name: "Value",
    sortKey: "VALUE",
  },
  {
    name: "Score",
    sortKey: "SCORE",
  },
  {
    name: "Address",
    sortKey: "ADDRESS",
  },
];

export const sortFns = {
  TIMESTAMP: (array: Array<any>) =>
    array.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
  VALUE: (array: Array<any>) =>
    array.sort((a, b) => a.value - b.value),
  SCORE: (array: Array<any>) =>
    array.sort((a, b) => a.value - b.value),
  ADDRESS: (array: Array<any>) =>
    array.sort((a, b) => a.address.localeCompare(b.address)),
};


const annotationFields = [
  {
    name: "Id",
    sortKey: "ANNOTATIONID",
  },
  {
    name: "Timestamp",
    sortKey: "ANNOTATIONTIMESTAMP",
  },
  {
    name: "Key",
    sortKey: "ANNOTATIONKEY",
  },
  {
    name: "Hash",
    sortKey: "ANNOTATIONHASH",
  },
  {
    name: "Host",
    sortKey: "ANNOTATIONHOST",
  },
  {
    name: "Kind",
    sortKey: "ANNOTATIONKIND",
  },
  {
    name: "Signature",
    sortKey: "ANNOTATIONSIGNATURE",
  },
  {
    name: "Satisfied",
    sortKey: "ANNOTATIONSATISFIED",
  },
]

const sortAnnFns = {
  ANNOTATIONID: (array: Array<any>) =>
    array.sort((a, b) => a.id - b.id),
  ANNOTATIONKEY: (array: Array<any>) =>
    array.sort((a, b) => a.key.localeCompare(b.key)),
  ANNOTATIONHASH: (array: Array<any>) =>
    array.sort((a, b) => a.hash.localeCompare(b.hash)),
  ANNOTATIONHOST: (array: Array<any>) =>
    array.sort((a, b) => a.host.localeCompare(b.host)),
  ANNOTATIONKIND: (array: Array<any>) =>
    array.sort((a, b) => a.kind.localeCompare(b.kind)),
  ANNOTATIONSIGNATURE: (array: Array<any>) =>
    array.sort((a, b) => a.signature.localeCompare(b.signature)),
  ANNOTATIONSATISFIED: (array: Array<any>) =>
    array.sort((a, b) => b.isSatisfied - a.isSatisfied),
  ANNOTATIONTIMESTAMP: (array: Array<any>) =>
    array.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
};

export const ReadingRowTemplate: React.FC<{ item: ReadingData }> = ({ item }) => {
  const [showAnnotations, setShowAnnotations] = useState<boolean>(false);
  const toggleAnnotations = () => setShowAnnotations((prev) => !prev);

  return (
    <>
      <Cell className={classes.timestamp} style={{cursor: "pointer"}} onClick={toggleAnnotations}>
        {timeToReadable(item.timestamp)}
      </Cell>
      <Cell className={classes.value} style={{cursor: "pointer"}} onClick={toggleAnnotations}>
        {item.value} {item.unit}
      </Cell>
      <Cell className={classes.value} style={{cursor: "pointer"}} onClick={toggleAnnotations}>
        {item.score} %
      </Cell>
      <Cell className={classes.address} style={{cursor: "pointer"}} onClick={toggleAnnotations}>
        <a href={ config.EXPLORER_URL + "/search/" + item.address } target="_blank">{item.address}</a>
      </Cell>
      {showAnnotations &&
          <Cell style={{width: "70vw"}} onclick={toggleAnnotations}>
              <TableSorting
                  className={classes.table}
                  title={`${item.annotations.length} Annotations`}
                  data={{ nodes: item.annotations }}
                  fields={annotationFields}
                  RowTemplate={AnnotationRowTemplate}
                  sortFns={sortAnnFns}
                  limit={10}
              />
          </Cell>
      }
      {showAnnotations && <Cell onclick={toggleAnnotations}/>}
      {showAnnotations && <Cell onclick={toggleAnnotations}/>}
      {showAnnotations && <Cell onclick={toggleAnnotations}/>}
    </>
  )}

const AnnotationRowTemplate: React.FC<{ item: Annotation }> = ({ item }) => {
  return (
    <>
      <Cell className={classes.value}>{item.id}</Cell>
      <Cell className={classes.timestamp}>{item.timestamp}</Cell>
      <Cell className={classes.value}>{item.key}</Cell>
      <Cell className={classes.value}>{item.hash}</Cell>
      <Cell className={classes.value}>{item.host}</Cell>
      <Cell className={classes.value}>{item.kind}</Cell>
      <Cell className={classes.value}>{item.signature}</Cell>
      <Cell className={classes.value}>{item.id ? item.isSatisfied ? "True" : "False" : ""}</Cell>
    </>
  )
}